import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

// Firebase
import { firestore } from "../../../firebase/firebase";
import { doc, doc as firebaseDoc, getDoc, updateDoc } from "firebase/firestore";

// Components
import TopBarForm from "./forms/TopBarForm";

// Mui core
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import Radio from "@mui/material/Radio";
import Grid from "@mui/material/Grid";

// Custom components
import GoalPickerDialog from "./dialogs/GoalPickerDialog";

// Assets
import trophy from "../../../assets/gifs/Trophy.gif";
import { MdKeyboardArrowRight } from "react-icons/md";
import plus from "../../../assets/icons/plus.png";

// Interfaces
import { StatTopic } from "../../../interfaces/Stat";

// Hooks
import { useFetchUser } from "../../../hooks/useFetchUser";

// Utils
import { getCurrentStage, getCurrentStageNumber } from "../../../utils/general";
import { getWeeklyChapters, getIconFromTopic, getTitleFromTopic, translateTopic } from "../../../utils/topic";

// Validation
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { isChapterCompleted } from "../../../utils/chapter";
import { genDoc } from "../../../firebase/requests";
import { Participant } from "../../../interfaces/Participant";

// yup validation
const requiredMessage = "Required field";

const Goals: React.FC = () => {
    const { t } = useTranslation();
    const hist = useHistory();
    const { currentParticipant, currentWeek, setLoading } = useFetchUser();
    const { enqueueSnackbar } = useSnackbar();
    const goal1PickerDialogRef = useRef(null);
    const goal2PickerDialogRef = useRef(null);
    const goal3PickerDialogRef = useRef(null);

    // States
    const [windowSize, setWindowSize] = useState<number>(window.innerWidth);
    const [boxHeight, setBoxHeight] = useState<number>();
    const [goal1, setGoal1] = useState("");
    const [goal2, setGoal2] = useState("");
    const [goal3, setGoal3] = useState("");

    const chapterSchema = yup.object({
        goal1: yup.string().required(requiredMessage),
        goal2: yup.string().required(requiredMessage),
        goal3: yup.string().required(requiredMessage),
        goal1Complete: yup.boolean(),
        goal2Complete: yup.boolean(),
        goal3Complete: yup.boolean(),
    });

    const defaultValues = {
        goal1: "",
        goal2: "",
        goal3: "",
        goal1Complete: true,
        goal2Complete: true,
        goal3Complete: true,
    };

    useEffect(() => {
        handleSize();
    }, []);

    const handleSize = () => {
        setWindowSize(window.innerWidth);
        setBoxHeight((windowSize - 50 - 32 * 3) / 4 / 2 - 150);
    };

    // Forms
    const {
        handleSubmit,
        formState: { errors },
        control,
        getValues,
        setValue,
    } = useForm({
        resolver: yupResolver(chapterSchema),
        defaultValues,
    });

    const updateTimeline = async () => {
        try {
            if (currentParticipant && currentParticipant.id) {
                // Refreshing the participant
                const payload = await getDoc(doc(firestore, "Participants", currentParticipant.id));
                const participant = genDoc<Participant>()(payload);

                if (currentParticipant.goals["stage1"].length === 0) {
                    // stage1
                    const weeklyChapters = await getWeeklyChapters(3, currentParticipant.id);
                    const weeklyIds: string[] = [];
                    weeklyChapters.forEach(c => c.id && weeklyIds.push(c.id));

                    await updateDoc(firebaseDoc(firestore, "Participants", currentParticipant.id), {
                        timeline: { ...participant.timeline, week03: weeklyIds },
                    });
                } else if (currentParticipant.goals["stage1"].length === 3 && currentParticipant.goals["stage2"].length === 0) {
                    // stage2
                    const weeklyChapters = await getWeeklyChapters(12, currentParticipant.id);
                    const weeklyIds: string[] = [];
                    weeklyChapters.forEach(c => c.id && weeklyIds.push(c.id));

                    await updateDoc(firebaseDoc(firestore, "Participants", currentParticipant.id), {
                        timeline: { ...participant.timeline, week12: weeklyIds },
                    });
                } else if (
                    currentParticipant.goals["stage1"].length === 3 &&
                    currentParticipant.goals["stage2"].length === 3 &&
                    currentParticipant.goals["stage3"].length === 0
                ) {
                    // stage3
                    const weeklyChapters = await getWeeklyChapters(23, currentParticipant.id);
                    const weeklyIds: string[] = [];
                    weeklyChapters.forEach(c => c.id && weeklyIds.push(c.id));

                    await updateDoc(firebaseDoc(firestore, "Participants", currentParticipant.id), {
                        timeline: { ...participant.timeline, week23: weeklyIds },
                    });
                } else if (
                    currentParticipant.goals["stage1"].length === 3 &&
                    currentParticipant.goals["stage2"].length === 3 &&
                    currentParticipant.goals["stage3"].length === 3 &&
                    currentParticipant.goals["stage4"].length === 0
                ) {
                    // stage4
                    const weeklyChapters = await getWeeklyChapters(34, currentParticipant.id);
                    const weeklyIds: string[] = [];
                    weeklyChapters.forEach(c => c.id && weeklyIds.push(c.id));

                    await updateDoc(firebaseDoc(firestore, "Participants", currentParticipant.id), {
                        timeline: { ...participant.timeline, week34: weeklyIds },
                    });
                }
            }
        } catch (e) {
            console.error(e);
        }
    };

    const onSubmit = async (data: any) => {
        try {
            setLoading(true);

            const stageNumber = getCurrentStageNumber(currentWeek);

            if (currentParticipant && currentParticipant.id) {
                let goals = {
                    ...currentParticipant.goals,
                    [`stage${stageNumber}`]: [
                        {
                            name: currentParticipant.prioritizedTopics[0],
                            description: data.goal1,
                            isComplete: false,
                        },
                        {
                            name: currentParticipant.prioritizedTopics[1],
                            description: data.goal2,
                            isComplete: false,
                        },
                        {
                            name: currentParticipant.prioritizedTopics[2],
                            description: data.goal3,
                            isComplete: false,
                        },
                    ],
                };

                if (getCurrentStageNumber(currentWeek) > 1) {
                    goals = {
                        ...goals,
                        [`stage${stageNumber - 1}`]: [
                            {
                                ...(currentParticipant.goals as any)[`stage${stageNumber - 1}`][0],
                                isComplete: (getValues("goal1Complete") as any) === "true",
                            },
                            {
                                ...(currentParticipant.goals as any)[`stage${stageNumber - 1}`][1],
                                isComplete: (getValues("goal2Complete") as any) === "true",
                            },
                            {
                                ...(currentParticipant.goals as any)[`stage${stageNumber - 1}`][2],
                                isComplete: (getValues("goal3Complete") as any) === "true",
                            },
                        ],
                    };
                }

                const updateData: any = { goals };

                if (!goal1) return enqueueSnackbar(t("selectGoal1"), { variant: "error" });
                if (!goal2) return enqueueSnackbar(t("selectGoal2"), { variant: "error" });
                if (!goal3) return enqueueSnackbar(t("selectGoal3"), { variant: "error" });

                if (
                    (getValues("goal1Complete") as any) === "true" &&
                    (getValues("goal2Complete") as any) === "true" &&
                    (getValues("goal3Complete") as any) === "true"
                ) {
                    updateData.points = currentParticipant.points + 1;

                    enqueueSnackbar(
                        <span>
                            {t(`1pointCompletedGained`)}
                            <b> 1 point </b>
                            {t(`1pointCompletedGoals`)}
                        </span>,
                        {
                            variant: "success",
                            anchorOrigin: {
                                horizontal: "center",
                                vertical: "bottom",
                            },
                            content: (key, message) => (
                                <div key={key} className="point__snackbar">
                                    <img alt="trophy" src={trophy} />
                                    <p>{message}</p>
                                </div>
                            ),
                        }
                    );
                } else {
                    enqueueSnackbar(t("goalsSelected"), { variant: "success" });
                }
                await updateTimeline();
                await updateDoc(firebaseDoc(firestore, "Participants", currentParticipant.id), updateData);
            }

            setLoading(false);

            if (!isChapterCompleted("h1BYNJTOhheE8tJ1khUk", currentParticipant)) {
                // Redirect to chapter 6.2
                hist.push("/chapter/h1BYNJTOhheE8tJ1khUk");
            } else hist.push("/home");
        } catch (e) {
            console.error(e);
        }
    };

    useEffect(() => {
        if (Object.values(errors).length > 0) {
            Object.entries(errors).map(e => {
                enqueueSnackbar(t(`desiredGoal${e[0].replace("goal", "")}`), { variant: "error" });
            });
        }
    }, [errors]);

    useEffect(() => {
        const stage = getCurrentStage(currentWeek);
        if ((currentParticipant as any).goals[stage].length === 3) hist.push("/home");
    }, [currentWeek]);

    return (
        <>
            <TopBarForm />
            <div className="goals">
                <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                    <h1>{t("goalsTitle")}</h1>

                    <Grid container spacing={4}>
                        <Grid item xs={12}>
                            {currentParticipant && currentParticipant.goals.stage1.length === 3 && (
                                <div className="goals__section">
                                    <h6 className="goals__questions">{t("goalsComplete")}</h6>
                                    {currentParticipant && getCurrentStageNumber(currentWeek) > 1 && (
                                        <Grid container spacing={4}>
                                            <Grid item xs={4} className="prioritizedTopic__item">
                                                {/* Goal1 */}
                                                <div className="goals__itemContainer">
                                                    <h6 className="goals__subsection">
                                                        {(currentParticipant.goals as any)[`stage${getCurrentStageNumber(currentWeek) - 1}`] &&
                                                            (currentParticipant.goals as any)[`stage${getCurrentStageNumber(currentWeek) - 1}`][0] &&
                                                            getTitleFromTopic(
                                                                (currentParticipant.goals as any)[`stage${getCurrentStageNumber(currentWeek) - 1}`][0]
                                                                    .name as StatTopic
                                                            )}
                                                    </h6>
                                                    <img
                                                        className="goals__icons"
                                                        src={getIconFromTopic(
                                                            (currentParticipant.goals as any)[`stage${getCurrentStageNumber(currentWeek) - 1}`][0]
                                                                .name as StatTopic
                                                        )}
                                                        alt=""
                                                    />
                                                    <p className="goals__previousWeek__description">
                                                        {(currentParticipant.goals as any)[`stage${getCurrentStageNumber(currentWeek) - 1}`] &&
                                                            (currentParticipant.goals as any)[`stage${getCurrentStageNumber(currentWeek) - 1}`][0] &&
                                                            (currentParticipant.goals as any)[`stage${getCurrentStageNumber(currentWeek) - 1}`][0]
                                                                .description}
                                                    </p>
                                                    <Controller
                                                        control={control}
                                                        name="goal1Complete"
                                                        render={({ field }) => (
                                                            <FormControl component="fieldset" error={!!errors.goal1Complete?.message}>
                                                                <RadioGroup row {...field}>
                                                                    <FormControlLabel
                                                                        value={true}
                                                                        control={<Radio />}
                                                                        label={localStorage.getItem("language") === "en" ? "Yes" : "Oui"}
                                                                    />
                                                                    <FormControlLabel
                                                                        value={false}
                                                                        control={<Radio />}
                                                                        label={localStorage.getItem("language") === "en" ? "No" : "Non"}
                                                                    />
                                                                </RadioGroup>
                                                                <FormHelperText>{errors.goal1Complete?.message}</FormHelperText>
                                                            </FormControl>
                                                        )}
                                                    />
                                                </div>
                                            </Grid>

                                            <Grid item xs={4} className="prioritizedTopic__item">
                                                {/* Goal2 */}
                                                <div className="goals__itemContainer">
                                                    <h6 className="goals__subsection">
                                                        {(currentParticipant.goals as any)[`stage${getCurrentStageNumber(currentWeek) - 1}`] &&
                                                            (currentParticipant.goals as any)[`stage${getCurrentStageNumber(currentWeek) - 1}`][1] &&
                                                            getTitleFromTopic(
                                                                (currentParticipant.goals as any)[`stage${getCurrentStageNumber(currentWeek) - 1}`][1]
                                                                    .name as StatTopic
                                                            )}
                                                    </h6>
                                                    <img
                                                        className="goals__icons"
                                                        src={getIconFromTopic(
                                                            (currentParticipant.goals as any)[`stage${getCurrentStageNumber(currentWeek) - 1}`][1]
                                                                .name as StatTopic
                                                        )}
                                                        alt=""
                                                    />
                                                    <p className="goals__previousWeek__description">
                                                        {(currentParticipant.goals as any)[`stage${getCurrentStageNumber(currentWeek) - 1}`] &&
                                                            (currentParticipant.goals as any)[`stage${getCurrentStageNumber(currentWeek) - 1}`][1] &&
                                                            (currentParticipant.goals as any)[`stage${getCurrentStageNumber(currentWeek) - 1}`][1]
                                                                .description}
                                                    </p>
                                                    <Controller
                                                        control={control}
                                                        name="goal2Complete"
                                                        render={({ field }) => (
                                                            <FormControl component="fieldset" error={!!errors.goal2Complete?.message}>
                                                                <RadioGroup row {...field}>
                                                                    <FormControlLabel
                                                                        value={true}
                                                                        control={<Radio />}
                                                                        label={localStorage.getItem("language") === "en" ? "Yes" : "Oui"}
                                                                    />
                                                                    <FormControlLabel
                                                                        value={false}
                                                                        control={<Radio />}
                                                                        label={localStorage.getItem("language") === "en" ? "No" : "Non"}
                                                                    />
                                                                </RadioGroup>
                                                                <FormHelperText>{errors.goal2Complete?.message}</FormHelperText>
                                                            </FormControl>
                                                        )}
                                                    />
                                                </div>
                                            </Grid>

                                            <Grid item xs={4} className="prioritizedTopic__item">
                                                {/* Goal3 */}
                                                <div className="goals__itemContainer">
                                                    <h6 className="goals__subsection">
                                                        {(currentParticipant.goals as any)[`stage${getCurrentStageNumber(currentWeek) - 1}`] &&
                                                            (currentParticipant.goals as any)[`stage${getCurrentStageNumber(currentWeek) - 1}`][2] &&
                                                            getTitleFromTopic(
                                                                (currentParticipant.goals as any)[`stage${getCurrentStageNumber(currentWeek) - 1}`][2]
                                                                    .name as StatTopic
                                                            )}
                                                    </h6>
                                                    <img
                                                        className="goals__icons"
                                                        src={getIconFromTopic(
                                                            (currentParticipant.goals as any)[`stage${getCurrentStageNumber(currentWeek) - 1}`][2]
                                                                .name as StatTopic
                                                        )}
                                                        alt=""
                                                    />
                                                    <p className="goals__previousWeek__description">
                                                        {(currentParticipant.goals as any)[`stage${getCurrentStageNumber(currentWeek) - 1}`] &&
                                                            (currentParticipant.goals as any)[`stage${getCurrentStageNumber(currentWeek) - 1}`][2] &&
                                                            (currentParticipant.goals as any)[`stage${getCurrentStageNumber(currentWeek) - 1}`][2]
                                                                .description}
                                                    </p>
                                                    <Controller
                                                        control={control}
                                                        name="goal3Complete"
                                                        render={({ field }) => (
                                                            <FormControl component="fieldset" error={!!errors.goal3Complete?.message}>
                                                                <RadioGroup row {...field}>
                                                                    <FormControlLabel
                                                                        value={true}
                                                                        control={<Radio />}
                                                                        label={localStorage.getItem("language") === "en" ? "Yes" : "Oui"}
                                                                    />
                                                                    <FormControlLabel
                                                                        value={false}
                                                                        control={<Radio />}
                                                                        label={localStorage.getItem("language") === "en" ? "No" : "Non"}
                                                                    />
                                                                </RadioGroup>
                                                                <FormHelperText>{errors.goal3Complete?.message}</FormHelperText>
                                                            </FormControl>
                                                        )}
                                                    />
                                                </div>
                                            </Grid>
                                        </Grid>
                                    )}
                                </div>
                            )}
                        </Grid>
                        <Grid item md={12}>
                            <div>
                                <h6>
                                    {t("pleaseEnter")} {currentParticipant && currentParticipant.goals.stage1.length === 3 && t("new")}{" "}
                                    {t("goalsTopics")}
                                </h6>
                            </div>
                        </Grid>
                        <Grid item lg={3} xs={12} className="prioritizedTopic__item">
                            <div
                                className="prioritizedTopic__itemContainer goals__boxPadding"
                                style={{ paddingTop: boxHeight, paddingBottom: boxHeight }}
                            >
                                {goal1 && currentParticipant ? (
                                    <div className="prioritizedTopic__itemContainer" style={{ paddingTop: boxHeight, paddingBottom: boxHeight }}>
                                        <img
                                            src={getIconFromTopic(currentParticipant.prioritizedTopics[0] as StatTopic)}
                                            alt={currentParticipant ? currentParticipant.prioritizedTopics[0] : ""}
                                            className="prioritizedTopic__img"
                                            onClick={() => {
                                                const current: any = goal1PickerDialogRef.current;
                                                if (current) {
                                                    current.open();
                                                }
                                            }}
                                        />
                                        <h6 className="prioritizedTopic__topic__title">
                                            {localStorage.getItem("language") === "en"
                                                ? currentParticipant.prioritizedTopics[0]
                                                : translateTopic(currentParticipant.prioritizedTopics[0])}
                                        </h6>
                                    </div>
                                ) : (
                                    <div className="prioritizedTopic__itemContainer" style={{ paddingTop: boxHeight, paddingBottom: boxHeight }}>
                                        <img
                                            src={plus}
                                            alt="plus"
                                            className="prioritizedTopic__img selection"
                                            onClick={() => {
                                                const current: any = goal1PickerDialogRef.current;
                                                if (current) {
                                                    current.open();
                                                }
                                            }}
                                            style={{ cursor: "cursor" }}
                                        />
                                        <h6>{t("selectGoal1")}</h6>
                                    </div>
                                )}
                            </div>
                        </Grid>
                        <Grid item lg={3} xs={12} className="prioritizedTopic__item">
                            <div
                                className="prioritizedTopic__itemContainer goals__boxPadding"
                                style={{ paddingTop: boxHeight, paddingBottom: boxHeight }}
                            >
                                {goal2 && currentParticipant ? (
                                    <div className="prioritizedTopic__itemContainer" style={{ paddingTop: boxHeight, paddingBottom: boxHeight }}>
                                        <img
                                            src={getIconFromTopic(currentParticipant.prioritizedTopics[1] as StatTopic)}
                                            alt={currentParticipant ? currentParticipant.prioritizedTopics[1] : ""}
                                            className="prioritizedTopic__img"
                                            onClick={() => {
                                                const current: any = goal2PickerDialogRef.current;
                                                if (current) {
                                                    current.open();
                                                }
                                            }}
                                        />
                                        <h6 className="prioritizedTopic__topic__title">
                                            {localStorage.getItem("language") === "en"
                                                ? currentParticipant.prioritizedTopics[1]
                                                : translateTopic(currentParticipant.prioritizedTopics[1])}
                                        </h6>
                                    </div>
                                ) : (
                                    <div className="prioritizedTopic__itemContainer" style={{ paddingTop: boxHeight, paddingBottom: boxHeight }}>
                                        <img
                                            src={plus}
                                            alt="plus"
                                            className="prioritizedTopic__img selection"
                                            onClick={() => {
                                                const current: any = goal2PickerDialogRef.current;
                                                if (current) {
                                                    current.open();
                                                }
                                            }}
                                            style={{ cursor: "cursor" }}
                                        />
                                        <h6>{t("selectGoal2")}</h6>
                                    </div>
                                )}
                            </div>
                        </Grid>
                        <Grid item lg={3} xs={12} className="prioritizedTopic__item">
                            <div
                                className="prioritizedTopic__itemContainer goals__boxPadding"
                                style={{ paddingTop: boxHeight, paddingBottom: boxHeight }}
                            >
                                {goal3 && currentParticipant ? (
                                    <div className="prioritizedTopic__itemContainer" style={{ paddingTop: boxHeight, paddingBottom: boxHeight }}>
                                        <img
                                            src={getIconFromTopic(currentParticipant.prioritizedTopics[2] as StatTopic)}
                                            alt={currentParticipant ? currentParticipant.prioritizedTopics[2] : ""}
                                            className="prioritizedTopic__img"
                                            onClick={() => {
                                                const current: any = goal3PickerDialogRef.current;
                                                if (current) {
                                                    current.open();
                                                }
                                            }}
                                        />
                                        <h6 className="prioritizedTopic__topic__title">
                                            {localStorage.getItem("language") === "en"
                                                ? currentParticipant.prioritizedTopics[2]
                                                : translateTopic(currentParticipant.prioritizedTopics[2])}
                                        </h6>
                                    </div>
                                ) : (
                                    <div className="prioritizedTopic__itemContainer" style={{ paddingTop: boxHeight, paddingBottom: boxHeight }}>
                                        <img
                                            src={plus}
                                            alt="plus"
                                            className="prioritizedTopic__img selection"
                                            onClick={() => {
                                                const current: any = goal3PickerDialogRef.current;
                                                if (current) {
                                                    current.open();
                                                }
                                            }}
                                            style={{ cursor: "cursor" }}
                                        />
                                        <h6>{t("selectGoal3")}</h6>
                                    </div>
                                )}
                            </div>
                        </Grid>
                        <Grid item lg={3} xs={12}>
                            <div className="questionnaires__buttonContainer">
                                <Button className="questionnaires__button" variant="contained" type="submit" color="primary">
                                    {t("confirm")}
                                    <MdKeyboardArrowRight />
                                </Button>
                            </div>
                        </Grid>
                    </Grid>
                </form>
            </div>
            <GoalPickerDialog
                setGoal={setGoal1}
                setValue={setValue}
                desiredGoal="goal1"
                topic={currentParticipant && currentParticipant.prioritizedTopics[0]}
                ref={goal1PickerDialogRef}
            />
            <GoalPickerDialog
                setGoal={setGoal2}
                setValue={setValue}
                desiredGoal="goal2"
                topic={currentParticipant && currentParticipant.prioritizedTopics[1]}
                ref={goal2PickerDialogRef}
            />
            <GoalPickerDialog
                setGoal={setGoal3}
                setValue={setValue}
                desiredGoal="goal3"
                topic={currentParticipant && currentParticipant.prioritizedTopics[2]}
                ref={goal3PickerDialogRef}
            />
        </>
    );
};

export default Goals;
