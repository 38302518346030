// Firebase
import { firestore } from "./firebase";
import {
    collection as firebaseCollection,
    getDocs,
    query,
    orderBy as firebaseOrderBy,
    where,
    getDoc,
    doc as firebaseDoc,
    setDoc,
    updateDoc,
    deleteDoc,
    onSnapshot as firebaseOnSnapshot,
    startAfter as firebaseStartAfter,
    limit,
    limitToLast,
    DocumentData,
    addDoc,
    serverTimestamp,
} from "firebase/firestore";
import { WhereFilterOp, OrderByDirection } from "@firebase/firestore-types";

// Interfaces
import { Base } from "../interfaces/Base";

// Settings
import settings from "../settings.json";

export interface Filter {
    field: string;
    operator: WhereFilterOp;
    value: any | any[];
}

export interface OrderBy {
    field: string;
    direction: OrderByDirection;
}

// this function is curried in order to pass a type, i.e. T, as a parameter.
// it is used to format the data receive from firestore into whatever we need.
//
// For exemple, firestore sends dates as { seconds, nanoseconds }. We can
// format them to an actual date.
// eslint-disable-next-line
export const genDoc: <T extends Base>() => (doc: DocumentData) => T =
    <T>() =>
    (doc: DocumentData) => {
        if (doc) {
            const data = doc.data();

            if (data) {
                if (data.dateOfBirth && data.dateOfBirth.seconds) data.dateOfBirth = new Date(data.dateOfBirth.seconds * 1000);
                if (data.cycleStart && data.cycleStart.seconds) data.cycleStart = new Date(data.cycleStart.seconds * 1000);
                if (data.createdAt && data.createdAt.seconds) data.createdAt = new Date(data.createdAt.seconds * 1000);
                if (data.updatedAt && data.updatedAt.seconds) data.updatedAt = new Date(data.updatedAt.seconds * 1000);
                if (data.vascularChapter7HiddenDate && data.vascularChapter7HiddenDate.seconds)
                    data.vascularChapter7HiddenDate = new Date(data.vascularChapter7HiddenDate.seconds * 1000);
                if (data.vascularChapter8HiddenDate && data.vascularChapter8HiddenDate.seconds)
                    data.vascularChapter8HiddenDate = new Date(data.vascularChapter8HiddenDate.seconds * 1000);
                if (data.uses && data.uses.length > 0) {
                    for (const use of data.uses) {
                        if (use.start) {
                            use.start = new Date(use.start.seconds * 1000);
                        }
                        if (use.end) {
                            use.end = new Date(use.end.seconds * 1000);
                        }
                    }
                }
                if (data.data) {
                    Object.entries(data.data).forEach(e => {
                        if (e[0].includes("date")) {
                            data.data[e[0]] = new Date((e[1] as any).seconds * 1000);
                        }
                    });
                }

                if (data.completedChaptersHistory) {
                    Object.entries(data.completedChaptersHistory).forEach(e => {
                        (e[1] as any[]).forEach((x, i) => {
                            if (x.date && x.date.seconds) {
                                data.completedChaptersHistory[e[0]][i] = {
                                    ...x,
                                    date: new Date(x.date.seconds * 1000),
                                };
                            }
                        });
                    });
                }
                if (data.unlockedChaptersHistory) {
                    Object.entries(data.unlockedChaptersHistory).forEach(e => {
                        (e[1] as any[]).forEach((x, i) => {
                            if (x.date && x.date.seconds) {
                                data.unlockedChaptersHistory[e[0]][i] = {
                                    ...x,
                                    date: new Date(x.date.seconds * 1000),
                                };
                            }
                        });
                    });
                }
            }

            return data as T;
        }
        return {} as T;
    };

// If the result of this call is an empty array there are a few possible outcomes.
// Since firestore does not throw an error when the collection DOES NOT exists,
// make sure that the collection name is accurate. The list may also be empty if
// there are not documents in the collection or if all the documents in the collection
// have their 'isDeleted' flag set to true
//
export async function getAll<T extends Base>(
    collection: string,
    filters: Filter[] = [],
    orderBy: OrderBy = { field: "createdAt", direction: "desc" },
    isDeleted = false,
    allData = false
): Promise<T[]> {
    let localQuery = null;

    if (allData) localQuery = query(firebaseCollection(firestore, collection), firebaseOrderBy(orderBy.field, orderBy.direction));
    else
        localQuery = query(
            firebaseCollection(firestore, collection),
            where("isDeleted", "==", isDeleted),
            firebaseOrderBy(orderBy.field, orderBy.direction)
        );

    let data = await getDocs(localQuery);

    if (filters.length === 0) return data.docs.map(genDoc<T>());

    // apply filters
    const firstFilter: Filter = filters[0];
    localQuery = query(localQuery, where(firstFilter.field, firstFilter.operator, firstFilter.value));

    // apply rest of filters
    for (const f of filters.slice(1, filters.length)) {
        localQuery = query(localQuery, where(f.field, f.operator, f.value));
    }

    data = await getDocs(localQuery);

    return data.docs.map(genDoc<T>());
}

export async function getById<T extends Base>(collection: string, id: string): Promise<T> {
    try {
        const data = await getDoc(firebaseDoc(firestore, collection, id));

        if (!data.exists) throw Error(`Cannot find doc with id ${id} in ${collection}`);

        const instance = genDoc<T>()(data);
        return instance;
    } catch (e) {
        console.error(e);
        return {} as T;
    }
}

export async function create<T extends Base>(collection: string, data: T): Promise<T> {
    if (!("isDeleted" in data)) data["isDeleted"] = false;
    if (!("createdAt" in data)) (data["createdAt"] as any) = serverTimestamp();
    if (!("createdAtTimestamp" in data)) data["createdAtTimestamp"] = Date.now();
    if (!("updatedAt" in data)) (data["updatedAt"] as any) = serverTimestamp();

    if (data.id) {
        await setDoc(firebaseDoc(firestore, collection, data.id), data);
        return getById(collection, data.id);
    }

    const collectionRef = firebaseCollection(firestore, collection);
    if (!collectionRef.id) throw Error(`Could not create doc in ${collection}: ${data}`);

    const newlyAddedDocRef = await addDoc(firebaseCollection(firestore, collection), data);
    const newlyAddedDoc = await getDoc(newlyAddedDocRef);

    await updateDoc(newlyAddedDocRef, { id: newlyAddedDoc.id });

    return getById(collection, newlyAddedDoc.id);
}

export async function firstFetch(collection: string, callback: any, acceptDeleted = false, filters: any[] = []) {
    onSnapshot(
        collection,
        callback,
        { field: "createdAtTimestamp", direction: "desc" },
        filters,
        undefined,
        acceptDeleted,
        undefined,
        undefined,
        true
    );
}

export async function fetchNextPage<T extends Base>(collection: string, callback: any, item: T, acceptDeleted = false, filters: any[] = []) {
    onSnapshot(collection, callback, { field: "createdAtTimestamp", direction: "desc" }, filters, undefined, acceptDeleted, item.createdAtTimestamp);
}

export async function fetchLastPage<T extends Base>(collection: string, callback: any, item: T, acceptDeleted = false, filters: any[] = []) {
    onSnapshot(
        collection,
        callback,
        { field: "createdAtTimestamp", direction: "desc" },
        filters,
        undefined,
        acceptDeleted,
        undefined,
        item.createdAtTimestamp
    );
}

export async function update<T extends Base>(collection: string, doc: T): Promise<T> {
    if (!doc.id) throw Error("Requests: id must be defined");

    const data: any = { ...doc, updatedAt: serverTimestamp() };

    await updateDoc(firebaseDoc(firestore, collection, doc.id), data);

    return getById(collection, doc.id);
}

export async function deleteById(collection: string, id: string, hard = false): Promise<boolean> {
    try {
        const docRef = firebaseDoc(firestore, collection, id);

        hard ? await deleteDoc(docRef) : await updateDoc(docRef, { isDeleted: true });

        return true;
    } catch (e) {
        console.error(e);
        return false;
    }
}

export function onSnapshot<T extends Base>(
    collection: string,
    callback: any,
    orderBy: OrderBy = { field: "createdAt", direction: "desc" },
    filters: Filter[] = [],
    id?: string,
    acceptDeleted = false,
    startAfter?: any,
    endBefore?: any,
    hasLimit = false
) {
    if (!!id && !!filters.length) throw Error("useDb: Error occured in onSnapshot. Filters cannot be combined with id");
    if (id) {
        return firebaseOnSnapshot(firebaseDoc(firestore, collection, id), doc => {
            if (!doc.exists) callback(null);

            const data = genDoc<T>()(doc);

            if (!acceptDeleted && data?.isDeleted) callback(null);

            callback(data);
        });
    }

    let localQuery = query(firebaseCollection(firestore, collection), firebaseOrderBy(orderBy.field, orderBy.direction));

    if (startAfter) localQuery = query(localQuery, firebaseStartAfter(startAfter), limit(settings.page.rowsPerPage));
    if (endBefore) localQuery = query(localQuery, endBefore(endBefore), limitToLast(settings.page.rowsPerPage));
    if (hasLimit) localQuery = query(localQuery, limit(settings.page.rowsPerPage));

    if (filters.length) {
        // apply filters
        for (const filter of filters) {
            localQuery = query(localQuery, where(filter.field, filter.operator, filter.value));
        }
    }

    return firebaseOnSnapshot(localQuery, doc => {
        const data = doc.docs.map(genDoc<T>()).filter((item: T) => {
            if (acceptDeleted) return true;
            return !item.isDeleted;
        });

        callback(data);
    });
}

export function onSnapshotWithDoc<T extends Base>(document: string, callback: any) {
    return firebaseOnSnapshot(firebaseDoc(firestore, document), doc => {
        if (!doc.exists) callback(null);

        const data = doc.data() as T;

        callback(data);
    });
}

export default { genDoc, getAll, getById, create, update, deleteById };
