import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

// Validation
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

// Mui
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import InputLabel from "@mui/material/InputLabel";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";

// Utils
import { helpEN, helpFR } from "../../../utils/emails";

// Firebase
import { functions } from "../../../firebase/firebase";
import { httpsCallable } from "firebase/functions";

// Icons
import { MdExpandMore } from "react-icons/md";

// Hooks
import { useFetchUser } from "../../../hooks/useFetchUser";
import useDb from "../../../hooks/useDb";

// Components
import Title from "../../general/Title";
import TopBarForm from "./forms/TopBarForm";

// Utils
import { emailRegex } from "../../../utils/regex";

// Interfaces
import { Participant } from "../../../interfaces/Participant";
import { isChapterCompleted } from "../../../utils/chapter";

// yup validation
const requiredMessage = "Required field";

interface Props {
    isLoggedOut?: boolean;
}

const Home: React.FC<Props> = ({ isLoggedOut }) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const hist = useHistory();
    const { currentParticipant, setLoading } = useFetchUser();
    const { updateDoc } = useDb<Participant>("Participants", null, currentParticipant);

    // States
    const [nickname, setNickname] = useState<string>("");

    const helpSchema: any = yup.object({
        type: yup.string().required(requiredMessage),
        message: yup.string().required(requiredMessage),
        email: isLoggedOut ? yup.string().matches(emailRegex, "Courriel invalide").required(requiredMessage) : yup.string(),
    });

    const defaultValues: any = {
        type: "",
        message: "",
        email: "",
    };

    // Forms
    const {
        handleSubmit,
        formState: { errors },
        control,
        setValue,
    } = useForm({ resolver: yupResolver(helpSchema), defaultValues });

    const resetForm = () => {
        setValue("type", "");
        setValue("message", "");
        setValue("email", "");
    };

    const onSubmit = async (data: any) => {
        try {
            setLoading(true);
            const sendEmail = httpsCallable(functions, "sendEmailSendGrid");

            await sendEmail({
                to: "support@brainhealthpro.ca",
                from: currentParticipant ? currentParticipant.email : data.email,
                subject: `${currentParticipant ? currentParticipant.email : data.email} - BHP Portal Contact - ${data.type}`,
                text: `BHP Portal Contact - ${data.type}`,
                html: localStorage.getItem("language") === "en" ? helpEN(data) : helpFR(data),
            });

            enqueueSnackbar(t("emailSent"), { variant: "success" });
            resetForm();
        } catch (e) {
            console.error(e);
        } finally {
            setLoading(false);
        }
    };

    const handleChangeNickname = async () => {
        if (currentParticipant) {
            await updateDoc({ ...currentParticipant, nickname: nickname } as Participant);
            enqueueSnackbar("Nickname changed", { variant: "success" });
        }
    };

    useEffect(() => {
        if (currentParticipant) setNickname(currentParticipant.nickname);
    }, [currentParticipant]);

    return (
        <>
            {isLoggedOut && <TopBarForm />}
            <div className="help">
                <Title>{!isLoggedOut ? t("help") : "Account Help"}</Title>

                <h6 className="help__subtitle">
                    {t("help1")} <br />
                    {t("help2")} <br />
                    &ensp;• {t("help3")} <br />
                    &ensp;• {t("help4")} <br />
                    &ensp;• {t("help5")}
                </h6>

                {!isLoggedOut && (
                    <div className="help__replayIntro">
                        {isChapterCompleted("z9FrQXipd4aNRgDmTzXC", currentParticipant) && (
                            <div onClick={() => hist.push("fullscreenChapter/z9FrQXipd4aNRgDmTzXC/true/false")}>
                                <h5>{t("replayIntro")}</h5>
                            </div>
                        )}
                        {isChapterCompleted("firstStep", currentParticipant) && (
                            <div onClick={() => hist.push("fullscreenChapter/firstStep/true/false")}>
                                <h5>{t("replayFirstStep")}</h5>
                            </div>
                        )}
                        {isChapterCompleted("interactiveActivities", currentParticipant) && (
                            <div onClick={() => hist.push("fullscreenChapter/interactiveActivities/true/false")}>
                                <h5>{t("replayInteractiveActivities")}</h5>
                            </div>
                        )}
                    </div>
                )}

                <br />
                <h3 className="help__subtitle">{t("faq")}</h3>

                <Accordion className="help__accordion">
                    <AccordionSummary expandIcon={<MdExpandMore />} aria-controls="panel1a-content" id="panel1a-header">
                        <Typography className="help__faq__questions">
                            <b>{t("question1")}</b>
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>{t("answer1")}</Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion className="help__accordion" style={{ marginTop: 20 }}>
                    <AccordionSummary expandIcon={<MdExpandMore />} aria-controls="panel1a-content" id="panel1a-header">
                        <Typography className="help__faq__questions">
                            <b>{t("question2")}</b>
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>{t("answer2")}</Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion className="help__accordion" style={{ marginTop: 20 }}>
                    <AccordionSummary expandIcon={<MdExpandMore />} aria-controls="panel1a-content" id="panel1a-header">
                        <Typography className="help__faq__questions">
                            <b>{t("question3")}</b>
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>{t("answer3")}</Typography>
                    </AccordionDetails>
                </Accordion>

                <br />
                <br />
                <br />
                <br />

                <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)} className="help__form">
                    <Controller
                        name="type"
                        control={control}
                        render={({ field }) => (
                            <FormControl fullWidth variant="standard" error={!!errors.type?.message}>
                                <InputLabel id="roles-id">
                                    <p>{t("helpType")}</p>
                                </InputLabel>
                                <Select labelId="roles-id" {...field}>
                                    <MenuItem value="technical">Technical</MenuItem>
                                    <MenuItem value="program">Program</MenuItem>
                                </Select>
                                {!!errors.type?.message && <FormHelperText>{errors.type?.message}</FormHelperText>}
                            </FormControl>
                        )}
                    />
                    {isLoggedOut && (
                        <Controller
                            render={({ field }) => (
                                <TextField
                                    variant="standard"
                                    helperText={errors.email?.message}
                                    error={!!errors.email?.message}
                                    label="Email"
                                    fullWidth
                                    {...field}
                                />
                            )}
                            name="email"
                            control={control}
                        />
                    )}

                    <Controller
                        render={({ field }) => (
                            <TextField
                                variant="standard"
                                helperText={errors.message?.message}
                                error={!!errors.message?.message}
                                multiline
                                rows={10}
                                label={<p>Message</p>}
                                fullWidth
                                {...field}
                            />
                        )}
                        name="message"
                        control={control}
                    />

                    <Button variant="contained" type="submit" className="help__submit" fullWidth color="primary" style={{ marginTop: 20 }}>
                        {t("submit")}
                    </Button>
                </form>

                {isLoggedOut ? (
                    <Button
                        variant="contained"
                        color="primary"
                        className="help__submit"
                        fullWidth
                        style={{ marginTop: 20 }}
                        onClick={() => hist.push("/")}
                    >
                        {t("back")}
                    </Button>
                ) : (
                    ""
                )}
            </div>

            <div className="topicsFavourited">
                <div className="tf__cardContainer">
                    <h5 style={{ marginBottom: 20 }}>Change Nickname</h5>

                    <div>
                        {currentParticipant && (
                            <OutlinedInput
                                label="Nickname"
                                fullWidth
                                value={nickname}
                                onChange={e => setNickname(e.target.value)}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <Button variant="contained" onClick={() => handleChangeNickname()}>
                                            Change
                                        </Button>
                                    </InputAdornment>
                                }
                            />
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default Home;
