import { t } from "i18next";
import React, { useEffect, useState } from "react";
import Title from "../../general/Title";
import { Accordion, AccordionDetails, AccordionSummary, Grid } from "@mui/material";
import { Chapter, ChapterTopic } from "../../../interfaces/Chapter";
import { collection, getDocs } from "firebase/firestore";
import { firestore } from "../../../firebase/firebase";
import { genDoc } from "../../../firebase/requests";
import { useHistory } from "react-router-dom";
import { MdExpandMore } from "react-icons/md";
import { useFetchUser } from "../../../hooks/useFetchUser";

const Library: React.FC = () => {
    const hist = useHistory();
    const { currentParticipant } = useFetchUser();

    // States
    const [chapters, setChapters] = useState<Chapter[]>([]);

    const fetchChapters = async () => {
        try {
            const payload = await getDocs(collection(firestore, "Chapters"));
            setChapters(payload.docs.map(genDoc<Chapter>()));
        } catch (e) {
            console.error(e);
        }
    };

    const isChapterHidden = (chapterId: string | undefined) => {
        if (currentParticipant) {
            // Chapter 7
            if (chapterId === "HfynqtVV8rA1e0YZTgPc" && currentParticipant.vascularChapter7HiddenDate) {
                return true;
            }
            // Chapter 8
            if (chapterId === "uR241p2bUtnWTj0e1WTU" && currentParticipant.vascularChapter8HiddenDate) {
                return true;
            }
        }
        return false;
    };

    const DisplayChaptersByTopic: React.FC<{ topic: ChapterTopic }> = ({ topic }) => {
        return (
            <>
                {chapters
                    .filter(chapter => chapter.topic === topic)
                    .sort((a, b) => a.chapter.toString().localeCompare(b.chapter.toString(), "en", { numeric: true }))
                    .map(chapter => (
                        <div
                            key={chapter.id}
                            onClick={() => hist.push(`/fullscreenChapter/${chapter.id}/true/true`)}
                            style={{
                                cursor: !isChapterHidden(chapter.id) ? "pointer" : "auto",
                                textDecoration: !isChapterHidden(chapter.id) ? "underline" : "none",
                                textDecorationColor: "rgb(15, 93, 119)",
                                color: "rgb(15, 93, 119)",
                            }}
                        >
                            {chapter.chapter} - {localStorage.getItem("language") === "en" ? chapter.titleEN : chapter.titleFR}
                        </div>
                    ))}
            </>
        );
    };

    useEffect(() => {
        fetchChapters();
    }, []);

    return (
        <>
            <Grid item container>
                <Grid item xs={12}>
                    <Title>{t("library")}</Title>
                </Grid>
                <Grid item xs={12} style={{ marginBottom: 20 }}>
                    <p>{t("libraryInfo")}</p>
                </Grid>
            </Grid>

            <div style={{ marginTop: 5, marginBottom: 20 }}>
                <Accordion defaultExpanded={false}>
                    <AccordionSummary expandIcon={<MdExpandMore />} style={{ fontSize: 25 }}>
                        {t("brainOverviewTitle")}
                    </AccordionSummary>
                    <AccordionDetails>
                        <DisplayChaptersByTopic topic="Brain Overview" />
                    </AccordionDetails>
                </Accordion>
                <Accordion defaultExpanded={false}>
                    <AccordionSummary expandIcon={<MdExpandMore />} style={{ fontSize: 25 }}>
                        {t("cognitiveTitle")}
                    </AccordionSummary>
                    <AccordionDetails>
                        <DisplayChaptersByTopic topic="Cognitive" />
                    </AccordionDetails>
                </Accordion>
                <Accordion defaultExpanded={false}>
                    <AccordionSummary expandIcon={<MdExpandMore />} style={{ fontSize: 25 }}>
                        {t("nutritionTitle")}
                    </AccordionSummary>
                    <AccordionDetails>
                        <DisplayChaptersByTopic topic="Nutrition" />
                    </AccordionDetails>
                </Accordion>
                <Accordion defaultExpanded={false}>
                    <AccordionSummary expandIcon={<MdExpandMore />} style={{ fontSize: 25 }}>
                        {t("physicalTitle")}
                    </AccordionSummary>
                    <AccordionDetails>
                        <DisplayChaptersByTopic topic="Physical" />
                    </AccordionDetails>
                </Accordion>
                <Accordion defaultExpanded={false}>
                    <AccordionSummary expandIcon={<MdExpandMore />} style={{ fontSize: 25 }}>
                        {t("sleepTitle")}
                    </AccordionSummary>
                    <AccordionDetails>
                        <DisplayChaptersByTopic topic="Sleep" />
                    </AccordionDetails>
                </Accordion>
                <Accordion defaultExpanded={false}>
                    <AccordionSummary expandIcon={<MdExpandMore />} style={{ fontSize: 25 }}>
                        {t("socialTitle")}
                    </AccordionSummary>
                    <AccordionDetails>
                        <DisplayChaptersByTopic topic="Social Psychological" />
                    </AccordionDetails>
                </Accordion>
                <Accordion defaultExpanded={false}>
                    <AccordionSummary expandIcon={<MdExpandMore />} style={{ fontSize: 25 }}>
                        {t("vascularTitle")}
                    </AccordionSummary>
                    <AccordionDetails>
                        <DisplayChaptersByTopic topic="Vascular Health" />
                    </AccordionDetails>
                </Accordion>
                <Accordion defaultExpanded={false}>
                    <AccordionSummary expandIcon={<MdExpandMore />} style={{ fontSize: 25 }}>
                        {t("visionTitle")}
                    </AccordionSummary>
                    <AccordionDetails>
                        <DisplayChaptersByTopic topic="Vision Hearing" />
                    </AccordionDetails>
                </Accordion>
            </div>
        </>
    );
};

export default Library;
