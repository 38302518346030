import { useSnackbar } from "notistack";

// Mui
import Switch from "@mui/material/Switch";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";

// Interfaces
import { Participant } from "../../../../interfaces/Participant";

// Firebase
import * as requests from "../../../../firebase/requests";
import { firestore, functions } from "../../../../firebase/firebase";
import { httpsCallable } from "firebase/functions";
import { doc, updateDoc } from "firebase/firestore";

// Utils
import { deactivatedParticipantsEN, deactivatedParticipantsFR, manualQuestionnairesEN, manualQuestionnairesFR } from "../../../../utils/emails";
import { weeksBetween } from "../../../../utils/date";

// Hooks
import { useFetchUser } from "../../../../hooks/useFetchUser";

// Icons
import { MdDisabledByDefault, MdSend } from "react-icons/md";

// Settings
import settings from "../../../../settings.json";
import { useState } from "react";

const COLLECTION = "Participants";

interface ConfirmationDialogProps {
    open: boolean;
    // eslint-disable-next-line
    setOpen: (value: boolean) => void;
    selectedParticipant: Participant | null;
}

const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({ open, setOpen, selectedParticipant }) => {
    const { currentAdmin } = useFetchUser();
    const { enqueueSnackbar } = useSnackbar();

    // States
    const [loading, setLoading] = useState(false);

    const handleDisable = async () => {
        try {
            setLoading(true);
            if (selectedParticipant) {
                if (currentAdmin && currentAdmin.roles && currentAdmin.roles.includes(settings.app.highestRole)) {
                    if (selectedParticipant.id) {
                        await requests.update<Participant>(COLLECTION, {
                            ...selectedParticipant,
                            deactivated: true,
                        });

                        const sendEmail = httpsCallable(functions, "sendEmailMailGun");

                        sendEmail({
                            to: selectedParticipant.email,
                            from: "support@brainhealthpro.ca",
                            subject: `${
                                localStorage.getItem("language") === "en"
                                    ? "Brain Health Pro - Account Deactivation"
                                    : "Santé Cerveau Pro : Désactivation de compte"
                            }`,
                            text: `${
                                localStorage.getItem("language") === "en"
                                    ? "Brain Health Pro - Account Deactivation"
                                    : "Santé Cerveau Pro : Désactivation de compte"
                            }`,
                            html:
                                localStorage.getItem("language") === "en"
                                    ? deactivatedParticipantsEN(selectedParticipant.nickname)
                                    : deactivatedParticipantsFR(selectedParticipant.nickname),
                        });
                    }

                    enqueueSnackbar("Participant deactivated", { variant: "success" });
                    setOpen(false);
                } else {
                    enqueueSnackbar("Missing required role", { variant: "error" });
                }
            } else enqueueSnackbar("Missing participant", { variant: "error" });
        } catch (e) {
            console.error(e);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Dialog open={open}>
            <DialogTitle id="dialog-title">Confirmation</DialogTitle>
            <DialogContent>
                <DialogContentText>Please confirm that you really want to deactivate this participant.</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button color="primary" variant="outlined" onClick={() => setOpen(false)} disabled={loading}>
                    Cancel
                </Button>
                <Button color="primary" variant="contained" onClick={() => handleDisable()} disabled={loading}>
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const S5QuestionnairesConfirmationDialog: React.FC<any> = ({ open, setOpen, selectedParticipant }) => {
    const { enqueueSnackbar } = useSnackbar();

    const [confirmPressed, setConfirmPressed] = useState(false);

    const handleAccept = async () => {
        if (selectedParticipant && selectedParticipant.id) {
            setConfirmPressed(true);

            await updateDoc(doc(firestore, "Participants", selectedParticipant.id), {
                manualS5: true,
            });

            const sendEmail = httpsCallable(functions, "sendEmailMailGun");

            await sendEmail({
                to: selectedParticipant.email,
                from: "support@brainhealthpro.ca",
                subject: `${
                    localStorage.getItem("language") === "en"
                        ? "Brain Health Pro - Questionnaires to fill"
                        : "Santé Cerveau Pro : Questionnaires à remplir"
                }`,
                text: `${
                    localStorage.getItem("language") === "en"
                        ? "Brain Health Pro - Questionnaires to fill"
                        : "Santé Cerveau Pro : Questionnaires à remplir"
                }`,
                html:
                    localStorage.getItem("language") === "en"
                        ? manualQuestionnairesEN(selectedParticipant.nickname)
                        : manualQuestionnairesFR(selectedParticipant.nickname),
            });

            enqueueSnackbar(localStorage.getItem("language") === "en" ? "Email sent" : "Courriel envoyé", {
                variant: "success",
            });

            setOpen(false);
        }
    };

    return (
        <Dialog open={open}>
            <DialogTitle id="dialog-title">Confirmation</DialogTitle>
            <DialogContent>
                <DialogContentText>Please confirm that you really want this participant to complete stage 5 questionnaires.</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button color="primary" variant="outlined" onClick={() => setOpen(false)} disabled={confirmPressed}>
                    Cancel
                </Button>
                <Button color="primary" variant="contained" onClick={() => handleAccept()} disabled={confirmPressed}>
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const ParticipantsTableRows = () => {
    const { currentAdmin } = useFetchUser();
    const { enqueueSnackbar } = useSnackbar();

    // States
    const [open, setOpen] = useState(false);
    const [s5ConfirmationOpen, setS5ConfirmationOpen] = useState(false);
    const [selectedParticipant, setSelectedParticipant] = useState<Participant | null>(null);

    const handleDelete = (p: Participant) => {
        try {
            if (currentAdmin && currentAdmin.roles && currentAdmin.roles.includes(settings.app.highestRole)) {
                if (p.id)
                    requests.update<Participant>(COLLECTION, {
                        ...p,
                        isDeleted: !p.isDeleted,
                    });
            } else {
                enqueueSnackbar("Missing required role", { variant: "error" });
            }
        } catch (e) {
            console.error(e);
        }
    };

    const handleLibraryAccess = (p: Participant) => {
        try {
            if (currentAdmin && currentAdmin.roles && currentAdmin.roles.includes(settings.app.highestRole)) {
                if (p.id) {
                    updateDoc(doc(firestore, "Participants", p.id), {
                        hasLibraryAccess: !p.hasLibraryAccess,
                    });
                }
            } else {
                enqueueSnackbar("Missing required role", { variant: "error" });
            }
        } catch (e) {
            console.error(e);
        }
    };

    return [
        {
            header: "PSCID",
            body: (p: Participant) => {
                if (p.pscid) return p.pscid;
                else return "-";
            },
        },
        {
            header: "Email",
            body: (p: Participant) => {
                if (currentAdmin && currentAdmin.roles.includes(settings.app.highestRole)) return p.email;
                else return "-";
            },
        },
        {
            header: "Nickname",
            body: (p: Participant) => p.nickname,
        },
        {
            header: "Current week",
            body: (p: Participant) => {
                const today = new Date();
                if (p.cycleStart) {
                    if (today >= p.cycleStart) return weeksBetween(today, p.cycleStart);
                    else return 1;
                }

                return 0;
            },
        },
        {
            header: "Deactivate",
            body: (p: Participant) => (
                <>
                    <IconButton edge="start" color="inherit" aria-label="open drawer" disabled={p.deactivated}>
                        <MdDisabledByDefault
                            onClick={() => {
                                setSelectedParticipant(p);
                                setOpen(true);
                            }}
                        />
                    </IconButton>
                    {open && <ConfirmationDialog open={open} setOpen={setOpen} selectedParticipant={selectedParticipant} />}
                </>
            ),
        },
        {
            header: "S5 Questionnaires",
            body: (p: Participant) => (
                <>
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        disabled={!currentAdmin?.roles.includes("superAdmin") || p.manualS5}
                    >
                        <MdSend
                            onClick={async () => {
                                setSelectedParticipant(p);
                                setS5ConfirmationOpen(true);
                            }}
                        />
                    </IconButton>
                    {s5ConfirmationOpen && (
                        <S5QuestionnairesConfirmationDialog
                            open={s5ConfirmationOpen}
                            setOpen={setS5ConfirmationOpen}
                            selectedParticipant={selectedParticipant}
                        />
                    )}
                </>
            ),
        },
        {
            header: "Is Active",
            body: (p: Participant) => (
                <Switch
                    checked={!p.isDeleted}
                    onChange={() => handleDelete(p)}
                    name="checkedA"
                    inputProps={{
                        "aria-label": "secondary checkbox",
                    }}
                />
            ),
        },
        {
            header: "Has library access",
            body: (p: Participant) => (
                <Switch
                    checked={p.hasLibraryAccess}
                    onChange={() => handleLibraryAccess(p)}
                    name="checkedA"
                    inputProps={{
                        "aria-label": "secondary checkbox",
                    }}
                />
            ),
        },
    ];
};

export default ParticipantsTableRows;
