import React, { useState } from "react";

// Mui
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";

// Components
import Title from "../../general/Title";
import { TextField } from "@mui/material";

// Hooks
import useDb from "../../../hooks/useDb";
import { useFetchUser } from "../../../hooks/useFetchUser";
import { useSnackbar } from "notistack";

// Interfaces
import { WhiteListUser } from "../../../interfaces/WhitelistUser";
import { Participant } from "../../../interfaces/Participant";

// Utils
import { emailRegex } from "../../../utils/regex";

const COLLECTION = "WhitelistUsers";

const Home: React.FC = () => {
    const { currentAdmin, setLoading } = useFetchUser();
    const { enqueueSnackbar } = useSnackbar();
    const participantsRequest = useDb<Participant>("Participants", currentAdmin);
    const { getAll, updateDoc, createDoc } = useDb<WhiteListUser>(COLLECTION, currentAdmin);

    // States
    const [open, setOpen] = useState(false);
    const [fetchedUser, setFetchedUser] = useState<WhiteListUser | null>(null);
    const [email, setEmail] = useState("");
    const [pscid, setPscid] = useState("");
    const [study, setStudy] = useState("");

    const handleSubmit = async (e: any) => {
        e.preventDefault();

        if (!email.match(emailRegex)) return enqueueSnackbar("Invalid email", { variant: "error" });
        if (!pscid) return enqueueSnackbar("PSCID is required", { variant: "error" });
        if (!study) return enqueueSnackbar("Study is required", { variant: "error" });

        const fetchedUsers = await getAll({
            filters: [{ field: "email", operator: "==", value: email.toLowerCase().trim() }],
        });

        if (fetchedUsers.length) {
            setFetchedUser(fetchedUsers[0] as WhiteListUser);
            setOpen(true);
        } else createNewUser();
    };

    const createNewUser = async () => {
        const whitelistUser: WhiteListUser = {
            email: email.toLowerCase().trim(),
            pscid,
            study,
        };

        setLoading(true);
        const createAdminUserPayload = await createDoc(whitelistUser);

        // Update participants if it exists
        const tempParticipants = (await participantsRequest.getAll({
            filters: [{ field: "email", operator: "==", value: whitelistUser.email }],
        })) as Participant[];

        if (tempParticipants.length) {
            participantsRequest.updateDoc({ ...tempParticipants[0], pscid, study } as Participant);
        }

        if (createAdminUserPayload) {
            enqueueSnackbar("Whitelist user created", { variant: "success" });
            resetFields();
        }
        setLoading(false);
    };

    const modifyUser = async (e: any) => {
        e.preventDefault();

        setLoading(true);

        if (fetchedUser) {
            await updateDoc({ ...fetchedUser, pscid, study } as Participant);

            // Update participants if it exists
            const tempParticipants = (await participantsRequest.getAll({
                filters: [{ field: "email", operator: "==", value: fetchedUser.email }],
            })) as Participant[];

            if (tempParticipants.length) {
                tempParticipants[0].pscid = pscid;
                tempParticipants[0].study = study;

                participantsRequest.updateDoc({ ...tempParticipants[0] } as Participant);
            }
        }

        setOpen(false);

        enqueueSnackbar("Whitelist user updated", { variant: "success" });
        resetFields();
        setLoading(false);
    };

    const resetFields = () => {
        setEmail("");
        setPscid("");
        setStudy("");
    };

    return (
        <div style={{ minHeight: 325 }}>
            <Title>Whitelist</Title>

            <TextField
                fullWidth
                variant="outlined"
                label="Email"
                value={email}
                onChange={e => setEmail(e.target.value.toLowerCase())}
                style={{ margin: 10 }}
            />
            <TextField
                fullWidth
                variant="outlined"
                label="PSCID"
                value={pscid}
                onChange={e => setPscid(e.target.value.toLowerCase())}
                style={{ margin: 10 }}
            />

            <FormControl fullWidth style={{ margin: 10 }}>
                <InputLabel id="demo-simple-select-label">Study</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={study}
                    label="Study"
                    onChange={e => setStudy(e.target.value)}
                >
                    <MenuItem value="ctubshp">CTU: BSHP</MenuItem>
                    <MenuItem value="synergic2">SYNERGIC 2</MenuItem>
                    <MenuItem value="sleep">SLEEP</MenuItem>
                </Select>
            </FormControl>

            <Button color="primary" variant="contained" onClick={handleSubmit} style={{ float: "right", margin: 10 }}>
                Submit
            </Button>

            <Dialog open={open} onClose={() => setOpen(false)} onaria-labelledby="form-dialog-title">
                <DialogTitle id="dialog-title">PSCID Modification</DialogTitle>
                <form onSubmit={modifyUser} method="post">
                    <DialogContent>
                        <DialogContentText>
                            You are about to change the user's PSCID
                            <br />
                            <br />
                            Old PSCID:{" "}
                            {pscid ? `****${fetchedUser && fetchedUser.pscid && fetchedUser.pscid.substring(fetchedUser.pscid.length - 4)}` : ""}
                            <br />
                            <br />
                            Do you wish to proceed?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button color="primary" variant="contained" type="submit">
                            Modify
                        </Button>
                        <Button color="secondary" variant="outlined" onClick={() => setOpen(false)}>
                            Cancel
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        </div>
    );
};

export default Home;
