import { useEffect } from "react";
import useQuery from "../../../hooks/useQuery";
import { functions } from "../../../firebase/firebase";
import { useFetchUser } from "../../../hooks/useFetchUser";
import { httpsCallable } from "firebase/functions";
import { useLocation } from "react-router-dom";

const SubDomainRedirection = () => {
    const query = useQuery();
    const { currentParticipant } = useFetchUser();
    const { pathname } = useLocation();

    // redirect to syn2 if pscid includes syn2
    useEffect(() => {
        const syn2Redirection = async () => {
            if (currentParticipant?.id && currentParticipant.study && !window.location.href.includes("completed/")) {
                const tokenQuery = query.get("token");
                const createCustomToken = httpsCallable(functions, "createCustomToken");
                const tokenPayload = await createCustomToken({ uid: currentParticipant.id });

                if (!window.location.href.includes("http://localhost:8283")) {
                    if (!tokenQuery) {
                        if (currentParticipant.study === "synergic2") {
                            window.location.href = `https://syn2.brainhealthpro.ca/?token=${tokenPayload.data}`;
                        }
                        if (currentParticipant.study === "sleep") {
                            window.location.href = `https://sleep-library.brainhealthpro.ca/?token=${tokenPayload.data}`;
                        }
                    }
                }
            }
        };
        syn2Redirection();
    }, [currentParticipant, pathname]);

    return <></>;
};

export default SubDomainRedirection;
