import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

// Components
import Title from "../../general/Title";

// Mui
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Tooltip } from "@mui/material";

// Assets
import { ImTrophy } from "react-icons/im";
import { HiOutlineHeart, HiHeart } from "react-icons/hi";

// Hooks
import { useFetchUser } from "../../../hooks/useFetchUser";
import useDb from "../../../hooks/useDb";

// Utils
import { getIconFromTopic, getTitleFromTopic } from "../../../utils/topic";
import { areAllChaptersCompleted, completeChapter } from "../../../utils/chapter";
import { getCurrentStageNumber } from "../../../utils/general";
import { getLastMonday } from "../../../utils/date";

// Interfaces
import { Chapter } from "../../../interfaces/Chapter";
import { Participant } from "../../../interfaces/Participant";

interface HomeCardProps {
    chapter: Chapter;
    isComplete: boolean;
    // eslint-disable-next-line
    completeChapter?: (chapter: Chapter) => void;
}

const HomeCard: React.FC<HomeCardProps> = ({ chapter, isComplete }) => {
    const { t } = useTranslation();
    const hist = useHistory();
    const { currentParticipant } = useFetchUser();
    const participantRequests = useDb<Participant>("Participants", null, currentParticipant);

    const handleSetFavourite = async () => {
        if (currentParticipant && currentParticipant.id && chapter.id) {
            let list: string[] = [];
            if (currentParticipant.favourites) list = currentParticipant.favourites;
            // if already favourited
            if (list.includes(chapter.id)) {
                list = list.filter(chap => chap !== chapter.id);
            } else {
                list.push(chapter.id);
            }
            participantRequests.updateDoc({ ...currentParticipant, favourites: list } as Participant);
        }
    };

    return (
        <div className="taskCard" style={{ opacity: isComplete ? 0.5 : 1 }}>
            <div
                className="taskCard__logo"
                style={{ cursor: "pointer" }}
                onClick={() => typeof completeChapter === "function" && hist.push(`/chapter/${chapter.id}`)}
            >
                <img src={getIconFromTopic(chapter.topic)} alt="" className="nofilterimg" />
            </div>
            <div
                className="taskCard__content"
                style={{ cursor: "pointer" }}
                onClick={() => typeof completeChapter === "function" && hist.push(`/chapter/${chapter.id}`)}
            >
                <h6>
                    {getTitleFromTopic(chapter.topic)} - {t("chapter")} {chapter.chapter}
                </h6>
                <h3>{localStorage.getItem("language") === "en" ? chapter.titleEN : chapter.titleFR}</h3>
                <Box className="progressbar__container" sx={{ display: "flex", alignItems: "center" }}>
                    <Box sx={{ minWidth: 35 }}>
                        <Typography variant="body2" color="black" />
                    </Box>
                    <div className={isComplete ? "progressbar__complete" : ""}></div>
                </Box>
            </div>

            <div className="taskCard__topRightCorner taskCard__pointer" onClick={handleSetFavourite}>
                {currentParticipant && chapter && chapter.id && currentParticipant.favourites.includes(chapter.id) ? (
                    <div className="taskCard__fullHearth">
                        <HiHeart />
                    </div>
                ) : (
                    <HiOutlineHeart />
                )}
            </div>
        </div>
    );
};

const Home: React.FC = () => {
    const { t } = useTranslation();
    const { currentParticipant, currentWeek } = useFetchUser();
    const participantRequests = useDb<Participant>("Participants", null, currentParticipant);
    const chapterRequests = useDb<Chapter>("Chapters", null, currentParticipant);

    // States
    const [unlockedChapters, setUnlockedChapters] = useState<Chapter[]>([]);
    const [allChaptersCompleted, setAllChaptersCompleted] = useState(false);
    const [adminButtonsDisabled, setAdminButtonsDisabled] = useState(false);

    const getTasksToCompleteCount = () => {
        let count = 0;
        if (currentParticipant && currentParticipant.unlockedChapters) {
            if (currentParticipant.unlockedChapters["Brain Overview"])
                count += currentParticipant.unlockedChapters["Brain Overview"].filter(
                    (x: any) => !currentParticipant.completedChapters["Brain Overview"].includes(x)
                ).length;

            if (currentParticipant.unlockedChapters["Cognitive"])
                count += currentParticipant.unlockedChapters["Cognitive"].filter(
                    (x: any) => !currentParticipant.completedChapters["Cognitive"].includes(x)
                ).length;

            if (currentParticipant.unlockedChapters["Nutrition"])
                count += currentParticipant.unlockedChapters["Nutrition"].filter(
                    (x: any) => !currentParticipant.completedChapters["Nutrition"].includes(x)
                ).length;

            if (currentParticipant.unlockedChapters["Physical"])
                count += currentParticipant.unlockedChapters["Physical"].filter(
                    (x: any) => !currentParticipant.completedChapters["Physical"].includes(x)
                ).length;

            if (currentParticipant.unlockedChapters["Sleep"])
                count += currentParticipant.unlockedChapters["Sleep"].filter(
                    (x: any) => !currentParticipant.completedChapters["Sleep"].includes(x)
                ).length;

            if (currentParticipant.unlockedChapters["Social Psychological"])
                count += currentParticipant.unlockedChapters["Social Psychological"].filter(
                    (x: any) => !currentParticipant.completedChapters["Social Psychological"].includes(x)
                ).length;

            if (currentParticipant.unlockedChapters["Vascular Health"])
                count += currentParticipant.unlockedChapters["Vascular Health"].filter(
                    (x: any) => !currentParticipant.completedChapters["Vascular Health"].includes(x)
                ).length;

            if (currentParticipant.unlockedChapters["Vision Hearing"])
                count += currentParticipant.unlockedChapters["Vision Hearing"].filter(
                    (x: any) => !currentParticipant.completedChapters["Vision Hearing"].includes(x)
                ).length;
        }
        return count;
    };

    const getTasksCompletedCount = () => {
        let count = 0;
        if (currentParticipant && currentParticipant.unlockedChapters) {
            if (currentParticipant.unlockedChapters["Brain Overview"])
                count += currentParticipant.unlockedChapters["Brain Overview"].filter((x: any) =>
                    currentParticipant.completedChapters["Brain Overview"].includes(x)
                ).length;

            if (currentParticipant.unlockedChapters["Cognitive"])
                count += currentParticipant.unlockedChapters["Cognitive"].filter((x: any) =>
                    currentParticipant.completedChapters["Cognitive"].includes(x)
                ).length;

            if (currentParticipant.unlockedChapters["Nutrition"])
                count += currentParticipant.unlockedChapters["Nutrition"].filter((x: any) =>
                    currentParticipant.completedChapters["Nutrition"].includes(x)
                ).length;

            if (currentParticipant.unlockedChapters["Physical"])
                count += currentParticipant.unlockedChapters["Physical"].filter((x: any) =>
                    currentParticipant.completedChapters["Physical"].includes(x)
                ).length;

            if (currentParticipant.unlockedChapters["Sleep"])
                count += currentParticipant.unlockedChapters["Sleep"].filter((x: any) =>
                    currentParticipant.completedChapters["Sleep"].includes(x)
                ).length;

            if (currentParticipant.unlockedChapters["Social Psychological"])
                count += currentParticipant.unlockedChapters["Social Psychological"].filter((x: any) =>
                    currentParticipant.completedChapters["Social Psychological"].includes(x)
                ).length;

            if (currentParticipant.unlockedChapters["Vascular Health"])
                count += currentParticipant.unlockedChapters["Vascular Health"].filter((x: any) =>
                    currentParticipant.completedChapters["Vascular Health"].includes(x)
                ).length;

            if (currentParticipant.unlockedChapters["Vision Hearing"])
                count += currentParticipant.unlockedChapters["Vision Hearing"].filter((x: any) =>
                    currentParticipant.completedChapters["Vision Hearing"].includes(x)
                ).length;
        }
        return count;
    };

    const fetchUnlockedChapters = async () => {
        let chapterIds: string[] = [];
        const localChapters: Chapter[] = [];

        if (currentParticipant && currentParticipant.unlockedChapters) {
            if (currentParticipant.unlockedChapters["Brain Overview"]) {
                const data = currentParticipant.unlockedChapters["Brain Overview"].filter(
                    (x: any) => !currentParticipant.completedChapters["Brain Overview"].includes(x)
                );
                chapterIds = chapterIds.concat(data);
            }

            if (currentParticipant.unlockedChapters["Cognitive"]) {
                const data = currentParticipant.unlockedChapters["Cognitive"].filter(
                    (x: any) => !currentParticipant.completedChapters["Cognitive"].includes(x)
                );
                chapterIds = chapterIds.concat(data);
            }

            if (currentParticipant.unlockedChapters["Nutrition"]) {
                const data = currentParticipant.unlockedChapters["Nutrition"].filter(
                    (x: any) => !currentParticipant.completedChapters["Nutrition"].includes(x)
                );
                chapterIds = chapterIds.concat(data);
            }

            if (currentParticipant.unlockedChapters["Physical"]) {
                const data = currentParticipant.unlockedChapters["Physical"].filter(
                    (x: any) => !currentParticipant.completedChapters["Physical"].includes(x)
                );
                chapterIds = chapterIds.concat(data);
            }

            if (currentParticipant.unlockedChapters["Sleep"]) {
                const data = currentParticipant.unlockedChapters["Sleep"].filter(
                    (x: any) => !currentParticipant.completedChapters["Sleep"].includes(x)
                );
                chapterIds = chapterIds.concat(data);
            }

            if (currentParticipant.unlockedChapters["Social Psychological"]) {
                const data = currentParticipant.unlockedChapters["Social Psychological"].filter(
                    (x: any) => !currentParticipant.completedChapters["Social Psychological"].includes(x)
                );
                chapterIds = chapterIds.concat(data);
            }

            if (currentParticipant.unlockedChapters["Vascular Health"]) {
                const data = currentParticipant.unlockedChapters["Vascular Health"].filter(
                    (x: any) => !currentParticipant.completedChapters["Vascular Health"].includes(x)
                );
                chapterIds = chapterIds.concat(data);
            }

            if (currentParticipant.unlockedChapters["Vision Hearing"]) {
                const data = currentParticipant.unlockedChapters["Vision Hearing"].filter(
                    (x: any) => !currentParticipant.completedChapters["Vision Hearing"].includes(x)
                );
                chapterIds = chapterIds.concat(data);
            }
        }

        if (chapterIds && chapterIds.length > 0) {
            for (const chapterId of chapterIds) {
                localChapters.push((await chapterRequests.getById(chapterId)) as Chapter);
            }
        }

        setUnlockedChapters(localChapters);
    };

    const checkChaptersCompletion = async () => {
        if (currentParticipant && currentParticipant.id) {
            setAllChaptersCompleted(await areAllChaptersCompleted(currentParticipant.id));
        }
    };

    const resetParticipantToWeek1 = async () => {
        setAdminButtonsDisabled(true);
        setTimeout(() => setAdminButtonsDisabled(false), 5000);

        let updateData: any = {};
        if (currentParticipant) {
            updateData = {
                ...currentParticipant,
                timeline: {
                    week01: [],
                    week02: [],
                    week03: [],
                    week04: [],
                    week05: [],
                    week06: [],
                    week07: [],
                    week08: [],
                    week09: [],
                    week10: [],
                    week11: [],
                    week12: [],
                    week13: [],
                    week14: [],
                    week15: [],
                    week16: [],
                    week17: [],
                    week18: [],
                    week19: [],
                    week20: [],
                    week21: [],
                    week22: [],
                    week23: [],
                    week24: [],
                    week25: [],
                    week26: [],
                    week27: [],
                    week28: [],
                    week29: [],
                    week30: [],
                    week31: [],
                    week32: [],
                    week33: [],
                    week34: [],
                    week35: [],
                    week36: [],
                    week37: [],
                    week38: [],
                    week39: [],
                    week40: [],
                    week41: [],
                    week42: [],
                    week43: [],
                    week44: [],
                    week45: [],
                    week46: [],
                    week47: [],
                    week48: [],
                    week49: [],
                    week50: [],
                    week51: [],
                },
                completedChapters: {
                    "Brain Overview": [],
                    Cognitive: [],
                    "Social Psychological": [],
                    Sleep: [],
                    "Vascular Health": [],
                    Nutrition: [],
                    Physical: [],
                    "Vision Hearing": [],
                },
                unlockedChapters: {
                    "Brain Overview": [],
                    Cognitive: [],
                    "Social Psychological": [],
                    Sleep: [],
                    "Vascular Health": [],
                    Nutrition: [],
                    Physical: [],
                    "Vision Hearing": [],
                },
                prioritizedTopics: [],
                cycleStart: undefined,
                stages: {
                    stage1: false,
                    stage2: false,
                    stage3: false,
                    stage4: false,
                    stage5: false,
                },
                goals: {
                    stage1: [],
                    stage2: [],
                    stage3: [],
                    stage4: [],
                    stage5: [],
                },
                points: 0,
                weeksInRow: 0,
                uses: [],
            };
        }

        delete updateData.cycleStart;

        const participantId = updateData.id;

        await participantRequests.createDoc({
            ...updateData,
            id: participantId,
        });
    };

    const resetParticipantToWeek2 = async () => {
        setAdminButtonsDisabled(true);
        setTimeout(() => setAdminButtonsDisabled(false), 5000);

        const localDate = new Date();

        if (currentParticipant) {
            await participantRequests.updateDoc({
                ...currentParticipant,
                timeline: {
                    week01: [],
                    week02: [],
                    week03: [],
                    week04: [],
                    week05: [],
                    week06: [],
                    week07: [],
                    week08: [],
                    week09: [],
                    week10: [],
                    week11: [],
                    week12: [],
                    week13: [],
                    week14: [],
                    week15: [],
                    week16: [],
                    week17: [],
                    week18: [],
                    week19: [],
                    week20: [],
                    week21: [],
                    week22: [],
                    week23: [],
                    week24: [],
                    week25: [],
                    week26: [],
                    week27: [],
                    week28: [],
                    week29: [],
                    week30: [],
                    week31: [],
                    week32: [],
                    week33: [],
                    week34: [],
                    week35: [],
                    week36: [],
                    week37: [],
                    week38: [],
                    week39: [],
                    week40: [],
                    week41: [],
                    week42: [],
                    week43: [],
                    week44: [],
                    week45: [],
                    week46: [],
                    week47: [],
                    week48: [],
                    week49: [],
                    week50: [],
                    week51: [],
                },
                completedChapters: {
                    "Brain Overview": [],
                    Cognitive: [],
                    "Social Psychological": [],
                    Sleep: [],
                    "Vascular Health": [],
                    Nutrition: [],
                    Physical: [],
                    "Vision Hearing": [],
                },
                unlockedChapters: {
                    "Brain Overview": [],
                    Cognitive: [],
                    "Social Psychological": [],
                    Sleep: [],
                    "Vascular Health": [],
                    Nutrition: [],
                    Physical: [],
                    "Vision Hearing": [],
                },
                prioritizedTopics: [],
                cycleStart: getLastMonday(localDate),
                stages: {
                    stage1: true,
                    stage2: false,
                    stage3: false,
                    stage4: false,
                    stage5: false,
                },
                goals: {
                    stage1: [],
                    stage2: [],
                    stage3: [],
                    stage4: [],
                    stage5: [],
                },
                points: 0,
                weeksInRow: 0,
                uses: [],
            } as Participant);
        }
    };

    const addWeek = async () => {
        if (currentParticipant && currentParticipant.cycleStart && currentWeek <= 51) {
            setAdminButtonsDisabled(true);
            setTimeout(() => setAdminButtonsDisabled(false), 5000);

            const localDate = new Date(currentParticipant.cycleStart);
            localDate.setDate(currentParticipant.cycleStart.getDate() - 7);

            await participantRequests.updateDoc({
                ...currentParticipant,
                cycleStart: localDate,
            } as Participant);
        }
    };

    const removeWeek = async () => {
        if (currentParticipant && currentParticipant.cycleStart && currentWeek > 2) {
            setAdminButtonsDisabled(true);
            setTimeout(() => setAdminButtonsDisabled(false), 5000);

            const localDate = new Date(currentParticipant.cycleStart);
            localDate.setDate(currentParticipant.cycleStart.getDate() + 7);

            await participantRequests.updateDoc({
                ...currentParticipant,
                cycleStart: localDate,
            } as Participant);
        }
    };

    useEffect(() => {
        fetchUnlockedChapters();
        checkChaptersCompletion();
    }, [currentWeek, currentParticipant]);

    return (
        <>
            {(window.location.href === "https://test.brainhealthpro.ca/home" ||
                window.location.href === "http://localhost:8283/home" ||
                window.location.href === "https://test.sleep-library.brainhealthpro.ca/home" ||
                window.location.href === "https://test.syn2.brainhealthpro.ca/home") && (
                <>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => resetParticipantToWeek1()}
                        style={{ marginRight: 10 }}
                        disabled={adminButtonsDisabled}
                    >
                        Reset Participant to week#1
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => resetParticipantToWeek2()}
                        style={{ marginRight: 10 }}
                        disabled={adminButtonsDisabled}
                    >
                        Reset Participant to week#2
                    </Button>
                    <Button variant="contained" color="primary" onClick={() => addWeek()} style={{ marginRight: 10 }} disabled={adminButtonsDisabled}>
                        + 1 week
                    </Button>
                    <Button variant="contained" color="primary" onClick={() => removeWeek()} disabled={adminButtonsDisabled}>
                        - 1 week
                    </Button>
                    <Grid item container>
                        <Grid item xs={3}>
                            <h6>Unlocked topics</h6>
                            <h6 style={{ fontSize: 12 }}>
                                Brain Overview: {currentParticipant && currentParticipant.unlockedChapters["Brain Overview"].length} / 16
                            </h6>
                            <h6 style={{ fontSize: 12 }}>
                                Physical: {currentParticipant && currentParticipant.unlockedChapters["Physical"].length} / 24
                            </h6>
                            <h6 style={{ fontSize: 12 }}>
                                Cognitive: {currentParticipant && currentParticipant.unlockedChapters["Cognitive"].length} / 24
                            </h6>
                            <h6 style={{ fontSize: 12 }}>
                                Nutrition: {currentParticipant && currentParticipant.unlockedChapters["Nutrition"].length} / 24
                            </h6>
                            <h6 style={{ fontSize: 12 }}>Sleep: {currentParticipant && currentParticipant.unlockedChapters["Sleep"].length} / 24</h6>
                            <h6 style={{ fontSize: 12 }}>
                                Social Psychological: {currentParticipant && currentParticipant.unlockedChapters["Social Psychological"].length} / 24
                            </h6>
                            <h6 style={{ fontSize: 12 }}>
                                Vascular Health: {currentParticipant && currentParticipant.unlockedChapters["Vascular Health"].length} / 23
                            </h6>
                            <h6 style={{ fontSize: 12 }}>
                                Vision Hearing: {currentParticipant && currentParticipant.unlockedChapters["Vision Hearing"].length} / 24
                            </h6>
                        </Grid>
                        <Grid item xs={3}>
                            <h6>Non completed topics</h6>
                            <h6 style={{ fontSize: 12 }}>
                                Brain Overview: {currentParticipant && 16 - currentParticipant.completedChapters["Brain Overview"].length}/ 16
                            </h6>
                            <h6 style={{ fontSize: 12 }}>
                                Physical: {currentParticipant && 24 - currentParticipant.completedChapters["Physical"].length} / 24
                            </h6>
                            <h6 style={{ fontSize: 12 }}>
                                Cognitive: {currentParticipant && 24 - currentParticipant.completedChapters["Cognitive"].length} / 24
                            </h6>
                            <h6 style={{ fontSize: 12 }}>
                                Nutrition: {currentParticipant && 24 - currentParticipant.completedChapters["Nutrition"].length} / 24
                            </h6>
                            <h6 style={{ fontSize: 12 }}>
                                Sleep: {currentParticipant && 24 - currentParticipant.completedChapters["Sleep"].length} / 24
                            </h6>
                            <h6 style={{ fontSize: 12 }}>
                                Social Psychological: {currentParticipant && 24 - currentParticipant.completedChapters["Social Psychological"].length}{" "}
                                / 24
                            </h6>
                            <h6 style={{ fontSize: 12 }}>
                                Vascular Health: {currentParticipant && 23 - currentParticipant.completedChapters["Vascular Health"].length} / 23
                            </h6>
                            <h6 style={{ fontSize: 12 }}>
                                Vision Hearing: {currentParticipant && 24 - currentParticipant.completedChapters["Vision Hearing"].length} / 24
                            </h6>
                        </Grid>
                        <Grid item xs={3}>
                            <h6>Prioritized Topics</h6>
                            {currentParticipant &&
                                currentParticipant.prioritizedTopics.map((t, i) => (
                                    <h6 key={i} style={{ fontSize: 12 }}>
                                        {i + 1}: {t}
                                    </h6>
                                ))}
                        </Grid>
                        <Grid item xs={3}>
                            <h6>Questionnaires</h6>
                            <h6 style={{ fontSize: 12 }}>
                                Stage 1 (week#1): {currentParticipant && currentParticipant.stages.stage1 ? "Done" : "Todo"}
                            </h6>
                            <h6 style={{ fontSize: 12 }}>
                                Stage 2 (week#12): {currentParticipant && currentParticipant.stages.stage2 ? "Done" : "Todo"}
                            </h6>
                            <h6 style={{ fontSize: 12 }}>
                                Stage 3 (week#23): {currentParticipant && currentParticipant.stages.stage3 ? "Done" : "Todo"}
                            </h6>
                            <h6 style={{ fontSize: 12 }}>
                                Stage 4 (week#34): {currentParticipant && currentParticipant.stages.stage4 ? "Done" : "Todo"}
                            </h6>
                            <h6 style={{ fontSize: 12 }}>
                                Stage 5 (week#45): {currentParticipant && currentParticipant.stages.stage5 ? "Done" : "Todo"}
                            </h6>
                        </Grid>
                    </Grid>
                </>
            )}

            <Title>
                {t("hi")}, {currentParticipant ? `${currentParticipant.nickname}` : "(name)"}
            </Title>
            {allChaptersCompleted && (
                <h3 style={{ marginBottom: 20 }}>
                    {localStorage.getItem("language") === "en"
                        ? "Congratulations, you have completed «Brain Health PRO»! Your site coordinator will contact you soon!"
                        : "Félicitations, vous avez complété le programme «Santé Cerveau PRO»! Votre coordonnateur/trice de site vous contactera bientôt!"}
                </h3>
            )}

            <h1>
                {t("week")}#{currentWeek}
            </h1>
            <Grid container spacing={3} className="home__container">
                <Grid item lg={8}>
                    <h4 className="home__greeting">
                        <span className="font__regular">
                            {getTasksToCompleteCount()} {t("task")}
                            {getTasksToCompleteCount() > 1 ? "s" : ""} {t("toComplete")}.
                            <br />
                            {getTasksCompletedCount()} {t("achieved")}
                            {getTasksCompletedCount() > 1 && localStorage.getItem("language") === "fr" ? "s" : ""} {t("hasCompleted")}!
                            <span className="progressbar__complete"></span>
                        </span>
                    </h4>
                    {unlockedChapters
                        .sort((a, b) => a.chapter - b.chapter)
                        .map((c, i) => {
                            return (
                                <HomeCard
                                    key={i}
                                    chapter={c}
                                    isComplete={currentParticipant && c.id ? currentParticipant.completedChapters[c.topic].includes(c.id) : false}
                                />
                            );
                        })}
                </Grid>
                <Grid item lg={4} className="sidebar__content">
                    <h4 className="sidebar__achievements">
                        {t("achievements")}{" "}
                        <Tooltip title={t("achievements_tooltip")}>
                            <span>?</span>
                        </Tooltip>
                    </h4>
                    <p>
                        {t("earned")} {currentParticipant && currentParticipant.points} point
                        {currentParticipant && currentParticipant.points > 1 ? "s" : ""}
                    </p>
                    {/* <br />
                    <h4>Completed Tasks</h4> 
                    */}
                    <br />
                    <h4>
                        <ImTrophy /> {currentParticipant && currentParticipant.weeksInRow} {t("week")}
                        {currentParticipant && currentParticipant.weeksInRow > 1 ? "s" : ""} {t("inARow")}
                    </h4>
                    {currentParticipant && currentParticipant.prioritizedTopics.length > 0 && (
                        <>
                            <Divider />
                            <h4>{t("prioritizedTopic")}</h4>
                            {currentParticipant.prioritizedTopics.map((t, i) => (
                                <h6 key={i} style={{ fontWeight: "bold" }}>
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        {i + 1}: {getTitleFromTopic(t)}
                                        <img src={getIconFromTopic(t)} alt={t} style={{ maxWidth: 25, marginLeft: 5 }} />
                                    </div>
                                </h6>
                            ))}
                        </>
                    )}
                    {currentParticipant &&
                        currentParticipant.goals &&
                        (currentParticipant.goals as any)[`stage${getCurrentStageNumber(currentWeek)}`] &&
                        (currentParticipant.goals as any)[`stage${getCurrentStageNumber(currentWeek)}`].length > 0 && (
                            <>
                                <Divider />
                                <h4>{t("goals")}</h4>
                            </>
                        )}

                    {currentParticipant &&
                        currentParticipant.goals &&
                        (currentParticipant.goals as any)[`stage${getCurrentStageNumber(currentWeek)}`] &&
                        (currentParticipant.goals as any)[`stage${getCurrentStageNumber(currentWeek)}`].map((g: any, i: number) => (
                            <div key={i}>
                                <h6 style={{ fontWeight: "bold" }}>{getTitleFromTopic(g.name)}</h6>
                                <p style={{ overflowWrap: "anywhere" }}>{g.description}</p>
                            </div>
                        ))}
                </Grid>
            </Grid>
        </>
    );
};

export default Home;
