import React, { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

// Custom components
import Title from "../../../../general/Title";
import TopBarForm from "../TopBarForm";
import InstructionDialog from "../InstructionDialog";

// Mui core
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import FormLabel from "@mui/material/FormLabel";
import FormHelperText from "@mui/material/FormHelperText";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import Typography from "@mui/material/Typography";
import RadioGroup from "@mui/material/RadioGroup";
import IconButton from "@mui/material/IconButton";
import { TextField } from "@mui/material";

// Hooks
import { useFetchUser } from "../../../../../hooks/useFetchUser";

// Validation
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

// Utils
import { dirtyValues, removeUndefinedInObject } from "../../../../../utils/general";
import { getScoreByQuestion } from "../../../../../utils/score";

// Icons
import { GrCircleInformation } from "react-icons/gr";
import { MdKeyboardArrowRight } from "react-icons/md";
import { Participant } from "../../../../../interfaces/Participant";

const requiredMessage = "Required field";

interface Props {
    // eslint-disable-next-line
    onComplete: (title: string, data: any, points: number, topic: string, participant?: Participant | undefined) => void;
}

const TITLE_FR = "Vos activités physiques";
const TITLE_EN = "Your Physical Activity";
const ABOUT_FR =
    "Ce questionnaire nous aidera à mieux vous connaître et à suivre votre progression. Dix minutes devraient être nécessaires pour le compléter. Essayez de terminer le questionnaire en une seule séance.";
const ABOUT_EN =
    "These questions will help us to know you better and see your progress. This should take about 10 minutes. Try to complete this task in one session.";
const INSTRUCTION_FR = "Répondez à chaque question au mieux de vos capacités.";
const INSTRUCTION_EN = "Answer each question to the best of your abilities.";

const PhysicalActivityForm: React.FC<Props> = ({ onComplete }) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const { setLoading } = useFetchUser();

    // States
    const [question2Skipped, setQuestion2Skipped] = useState(true);
    const [q5Checked, setQ5Checked] = useState(false);
    const [question4Skipped, setQuestion4Skipped] = useState(true);
    const [question6Skipped, setQuestion6Skipped] = useState(true);
    const [q4Checked, setQ4Checked] = useState(false);
    const [q6Checked, setQ6Checked] = useState(false);
    const [q7Checked, setQ7Checked] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [selectedTypes, setSelectedTypes] = useState<string[]>([]);
    const [isSafeToReset, setIsSafeToReset] = useState(false);

    const generateSchema = () => {
        const output: any = {};

        output.question1 = yup.number().required(requiredMessage);
        if (!question2Skipped) {
            output.question2 = yup.number().typeError(requiredMessage).min(10).required(requiredMessage);
        } else {
            output.question2 = yup.mixed<string | number>();
        }
        output.question4 = yup.number().required(requiredMessage);
        if (!question4Skipped) {
            output.question4 = yup.number().typeError(requiredMessage).min(10).required(requiredMessage);
        } else {
            output.question4 = yup.mixed<string | number>();
        }
        output.question5 = yup.number().required(requiredMessage);
        if (!question6Skipped) {
            output.question6 = yup.number().typeError(requiredMessage).min(10).required(requiredMessage);
        } else {
            output.question6 = yup.mixed<string | number>();
        }
        output.question7 = yup.string().required(requiredMessage);

        return yup.object(output);
    };

    const generateDefaultValues = () => {
        const output: any = {};

        output.question1 = "";
        output.question2 = "";
        output.question3 = "";
        output.question4 = "";
        output.question5 = "";
        output.question6 = "";
        output.question7 = "";

        return output;
    };

    // Forms
    const {
        reset,
        handleSubmit,
        formState: { errors, dirtyFields },
        control,
    } = useForm({ resolver: yupResolver(generateSchema()), defaultValues: generateDefaultValues() });

    const onSubmit = async (data: any) => {
        try {
            let points = 0;
            data = removeUndefinedInObject(dirtyValues(dirtyFields, data));
            setLoading(true);

            Object.entries(dirtyValues(dirtyFields, data)).forEach(e => {
                const localPoints = getScoreByQuestion(
                    "Physical",
                    parseInt((e[0] as string).replace("question", "")),
                    dirtyValues(dirtyFields, data)
                );

                if (typeof localPoints !== "undefined" && localPoints !== "n/a") points += localPoints as number;
            });

            enqueueSnackbar(t("questionnaireCompleted", { title: localStorage.getItem("language") === "en" ? TITLE_EN : TITLE_FR }), {
                variant: "success",
            });
            onComplete(TITLE_EN, dirtyValues(dirtyFields, data), points, "Physical", dirtyValues(dirtyFields, data));
            window.scrollTo(0, 0);
            setIsSafeToReset(true);
        } catch (e) {
            console.error(e);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (Object.entries(errors).length > 0) {
            const id = Object.entries(errors)[0][0];
            const element = document.getElementById(id);

            if (element) element.scrollIntoView({ block: "center" });
        }
    }, [errors]);

    useEffect(() => {
        if (isSafeToReset) reset();
    }, [isSafeToReset]);

    return (
        <>
            <TopBarForm />
            <div className="forms__container">
                <Title>{localStorage.getItem("language") === "en" ? TITLE_EN : TITLE_FR}</Title>

                <div className="forms__section">
                    <h6 className="forms__subtitle">{localStorage.getItem("language") === "en" ? "About" : "À propos"}</h6>
                    <p>{localStorage.getItem("language") === "en" ? ABOUT_EN : ABOUT_FR}</p>
                </div>

                <div className="forms__section">
                    <h6 className="forms__subtitle">Instructions</h6>
                    <p>{localStorage.getItem("language") === "en" ? INSTRUCTION_EN : INSTRUCTION_FR}</p>
                </div>

                <form
                    noValidate
                    autoComplete="off"
                    onSubmit={handleSubmit(onSubmit)}
                    onKeyDown={(e: any) => {
                        // Disable submit on "enter" key press
                        e.key === "Enter" && e.preventDefault();
                    }}
                    className="forms__content"
                >
                    <Grid item>
                        <div>
                            <div className="forms__important__container">
                                <br />
                                <br />
                                <h6 className="forms__important">
                                    &emsp;
                                    {localStorage.getItem("language") === "en"
                                        ? "We are interested in finding out about the kinds of physical activities that people do as part of their everyday lives. The questions will ask you about the time you spent being physically active in the last 7 days. Please answer each question even if you do not consider yourself to be an active person. Please think about the activities you do at work, as part of your house and yard work, to get from place to place, and in your spare time for recreation, exercise or sport."
                                        : "Nous souhaitons connaître le genre d’activités physiques que les gens font dans leur vie quotidienne. Les questions porteront sur le temps que vous avez passé à faire de l’activité physique au cours des sept derniers jours. Veuillez répondre à chaque question même si vous ne vous considérez pas comme une personne active. Pensez aux activités physiques que vous faites au travail, à vos tâches ménagères ou votre jardinage, vos déplacements à pied, et à vos moments libres que vous consacrez aux loisirs et à faire de l’exercice ou du sport."}
                                </h6>
                            </div>

                            <div className="forms__important__container">
                                <h6 className="forms__important">
                                    &emsp;
                                    {localStorage.getItem("language") === "en"
                                        ? "Think about all the vigorous activities that you did in the last 7 days. Vigorous physical activities refer to activities that take hard physical effort and make you breathe much harder than normal."
                                        : "Pensez à toutes les activités vigoureuses que vous avez faites au cours des 7 derniers jours. Les activités physiques vigoureuses désignent les activités qui demandent un effort physique intense et qui vous font respirer beaucoup plus fort que la normale."}
                                </h6>
                            </div>

                            <Controller
                                control={control}
                                name="question1"
                                render={({ field }) => (
                                    <FormControl
                                        id="question1"
                                        component="fieldset"
                                        error={!!errors.question1?.message}
                                        {...field}
                                        onChange={(e: any) => {
                                            field.onChange(e);
                                            setQuestion2Skipped(parseInt(e.target.value) === 0);
                                        }}
                                    >
                                        <FormLabel component="legend">
                                            {localStorage.getItem("language") === "en"
                                                ? "During the last 7 days, on how many days did you do vigorous physical activities like heavy lifting, digging, aerobics, or fast bicycling for at least 10 minutes at a time?"
                                                : "Au cours des 7 derniers jours, combien de jours avez-vous fait des activités physiques vigoureuses comme soulever des charges lourdes, creuser, faire de l'exercice aérobique ou du vélo rapide pendant au moins 10 minutes à la fois ?"}
                                            <IconButton
                                                color="primary"
                                                style={{ cursor: "pointer" }}
                                                onClick={() => {
                                                    setSelectedTypes(["input"]);
                                                    setDialogOpen(true);
                                                }}
                                            >
                                                <GrCircleInformation />
                                            </IconButton>
                                        </FormLabel>
                                        <RadioGroup row className="forms__radioGroupContainer">
                                            <FormControlLabel value={0} control={<Radio />} label="0" />
                                            <FormControlLabel value={1} control={<Radio />} label="1" />
                                            <FormControlLabel value={2} control={<Radio />} label="2" />
                                            <FormControlLabel value={3} control={<Radio />} label="3" />
                                            <FormControlLabel value={4} control={<Radio />} label="4" />
                                            <FormControlLabel value={5} control={<Radio />} label="5" />
                                            <FormControlLabel value={6} control={<Radio />} label="6" />
                                            <FormControlLabel value={7} control={<Radio />} label="7" />
                                        </RadioGroup>
                                    </FormControl>
                                )}
                            />
                            {!question2Skipped && (
                                <Controller
                                    control={control}
                                    name="question2"
                                    render={({ field }) => (
                                        <FormControl id="question2" component="fieldset" error={!!errors.question2?.message} {...field}>
                                            <FormLabel component="legend">
                                                {localStorage.getItem("language") === "en"
                                                    ? "How much time did you usually spend doing vigorous physical activities on one of those days?"
                                                    : "Combien de temps avez-vous passé en moyenne à faire des activités physiques vigoureuses lors d'une de ces journées?"}
                                                <IconButton
                                                    color="primary"
                                                    style={{ cursor: "pointer" }}
                                                    onClick={() => {
                                                        setSelectedTypes(["input", "radio"]);
                                                        setDialogOpen(true);
                                                    }}
                                                >
                                                    <GrCircleInformation />
                                                </IconButton>
                                            </FormLabel>
                                            <Grid container>
                                                <Grid item xs={6}>
                                                    <TextField
                                                        type="number"
                                                        InputProps={{ inputProps: { min: 10 } }}
                                                        variant="standard"
                                                        helperText={errors.question2?.message}
                                                        error={!!errors.question2?.message}
                                                        label={<Typography variant="h6">Minutes</Typography>}
                                                        {...field}
                                                        onChange={(e: any) => {
                                                            if (e.target.value) {
                                                                // check to see if the input is a number
                                                                if (!isNaN(Number(e))) {
                                                                    field.onChange({ ...e, value: parseInt(e) });
                                                                } else {
                                                                    field.onChange({ ...e, value: 0 });
                                                                }
                                                            } else {
                                                                field.onChange(e);
                                                            }
                                                            setQ5Checked(e.target.checked);
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <FormControlLabel
                                                        value={0}
                                                        control={<Radio checked={q5Checked} onChange={e => setQ5Checked(e.target.checked)} />}
                                                        label={
                                                            localStorage.getItem("language") === "en"
                                                                ? "Don’t Know / Not Sure"
                                                                : "Ne sait pas / N’est pas sûr"
                                                        }
                                                    />
                                                </Grid>
                                            </Grid>
                                        </FormControl>
                                    )}
                                />
                            )}

                            <div className="forms__important__container">
                                <h6 className="forms__important">
                                    &emsp;
                                    {localStorage.getItem("language") === "en"
                                        ? "Think about all the moderate activities that you did in the last 7 days. Moderate activities refer to activities that take moderate physical effort and make you breathe somewhat harder than normal."
                                        : "Pensez à toutes les activités modérées que vous avez faites au cours des 7 derniers jours. Les activités modérées sont des activités qui exigent un effort physique modéré et qui vous font respirer un peu plus fort que la normale."}
                                </h6>
                            </div>

                            <Controller
                                control={control}
                                name="question3"
                                render={({ field }) => (
                                    <FormControl
                                        id="question3"
                                        component="fieldset"
                                        error={!!errors.question3?.message}
                                        {...field}
                                        onChange={(e: any) => {
                                            field.onChange(e);
                                            setQuestion4Skipped(parseInt(e.target.value) === 0);
                                        }}
                                    >
                                        <FormLabel component="legend">
                                            {localStorage.getItem("language") === "en"
                                                ? "During the last 7 days, on how many days did you do moderate physical activities like carrying light loads, bicycling at a regular pace, or doubles tennis for at least 10 minutes at a time? Do not include walking."
                                                : "Au cours des 7 derniers jours, combien de jours avez-vous fait des activités physiques modérées, telles que transporter des charges légères, faire du vélo à un rythme régulier ou faire du tennis en double pendant au moins 10 minutes à la fois ? N'incluez pas la marche."}
                                            <IconButton
                                                color="primary"
                                                style={{ cursor: "pointer" }}
                                                onClick={() => {
                                                    setSelectedTypes(["input"]);
                                                    setDialogOpen(true);
                                                }}
                                            >
                                                <GrCircleInformation />
                                            </IconButton>
                                        </FormLabel>
                                        <RadioGroup row className="forms__radioGroupContainer">
                                            <FormControlLabel value={0} control={<Radio />} label="0" />
                                            <FormControlLabel value={1} control={<Radio />} label="1" />
                                            <FormControlLabel value={2} control={<Radio />} label="2" />
                                            <FormControlLabel value={3} control={<Radio />} label="3" />
                                            <FormControlLabel value={4} control={<Radio />} label="4" />
                                            <FormControlLabel value={5} control={<Radio />} label="5" />
                                            <FormControlLabel value={6} control={<Radio />} label="6" />
                                            <FormControlLabel value={7} control={<Radio />} label="7" />
                                        </RadioGroup>
                                    </FormControl>
                                )}
                            />

                            {!question4Skipped && (
                                <Controller
                                    control={control}
                                    name="question4"
                                    render={({ field }) => (
                                        <FormControl id="question4" component="fieldset" error={!!errors.question4?.message} {...field}>
                                            <FormLabel component="legend">
                                                {localStorage.getItem("language") === "en"
                                                    ? "How much time did you usually spend doing moderate physical activities on one of those days?  "
                                                    : "Combien de temps avez-vous passé en moyenne à faire des activités physiques modérées lors d'une de ces journées? "}
                                                <IconButton
                                                    color="primary"
                                                    style={{ cursor: "pointer" }}
                                                    onClick={() => {
                                                        setSelectedTypes(["radio", "input"]);
                                                        setDialogOpen(true);
                                                    }}
                                                >
                                                    <GrCircleInformation />
                                                </IconButton>
                                            </FormLabel>
                                            <Grid container>
                                                <Grid item xs={6}>
                                                    <TextField
                                                        type="number"
                                                        InputProps={{ inputProps: { min: 0, max: 1440 } }}
                                                        variant="standard"
                                                        helperText={errors.question4?.message}
                                                        error={!!errors.question4?.message}
                                                        label={<Typography variant="h6">Minutes</Typography>}
                                                        {...field}
                                                        onChange={(e: any) => {
                                                            // check to see if the input is a number
                                                            if (!isNaN(Number(e))) {
                                                                field.onChange({ ...e, value: parseInt(e) });
                                                            } else {
                                                                field.onChange({ ...e, value: 0 });
                                                            }
                                                            setQ4Checked(e.target.checked);
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <FormControlLabel
                                                        value="0"
                                                        control={<Radio checked={q4Checked} onChange={e => setQ4Checked(e.target.checked)} />}
                                                        label={
                                                            localStorage.getItem("language") === "en"
                                                                ? "Don’t Know / Not Sure"
                                                                : "Ne sait pas / N’est pas sûr"
                                                        }
                                                    />
                                                </Grid>
                                            </Grid>
                                        </FormControl>
                                    )}
                                />
                            )}

                            <div className="forms__important__container">
                                <h6 className="forms__important">
                                    &emsp;
                                    {localStorage.getItem("language") === "en"
                                        ? "Think about the time you spent walking in the last 7 days. This includes at work and at home, walking to travel from place to place, and any other walking that you have done solely for recreation, sport, exercise, or leisure."
                                        : "Pensez au temps que vous avez passé à marcher au cours des 7 derniers jours. Cela comprend les pas que vous avez faits au travail et à la maison, tous vos déplacements à pied, et toutes les marches que vous avez faites exclusivement dans le cadre de vos activités récréatives, sportives, ou de loisirs."}
                                </h6>
                            </div>

                            <Controller
                                control={control}
                                name="question5"
                                render={({ field }) => (
                                    <FormControl
                                        id="question5"
                                        component="fieldset"
                                        error={!!errors.question5?.message}
                                        {...field}
                                        onChange={(e: any) => {
                                            field.onChange(e);
                                            setQuestion6Skipped(parseInt(e.target.value) === 0);
                                        }}
                                    >
                                        <FormLabel component="legend">
                                            {localStorage.getItem("language") === "en"
                                                ? "During the last 7 days, on how many days did you walk for at least 10 minutes at a time?"
                                                : "Au cours des 7 derniers jours, combien de jours avez-vous marché pendant au moins 10 minutes à la fois? "}
                                            <IconButton
                                                color="primary"
                                                style={{ cursor: "pointer" }}
                                                onClick={() => {
                                                    setSelectedTypes(["input"]);
                                                    setDialogOpen(true);
                                                }}
                                            >
                                                <GrCircleInformation />
                                            </IconButton>
                                        </FormLabel>
                                        <RadioGroup row className="forms__radioGroupContainer">
                                            <FormControlLabel value={0} control={<Radio />} label="0" />
                                            <FormControlLabel value={1} control={<Radio />} label="1" />
                                            <FormControlLabel value={2} control={<Radio />} label="2" />
                                            <FormControlLabel value={3} control={<Radio />} label="3" />
                                            <FormControlLabel value={4} control={<Radio />} label="4" />
                                            <FormControlLabel value={5} control={<Radio />} label="5" />
                                            <FormControlLabel value={6} control={<Radio />} label="6" />
                                            <FormControlLabel value={7} control={<Radio />} label="7" />
                                        </RadioGroup>
                                    </FormControl>
                                )}
                            />

                            {!question6Skipped && (
                                <Controller
                                    control={control}
                                    name="question6"
                                    render={({ field }) => (
                                        <FormControl id="question6" component="fieldset" error={!!errors.question6?.message} {...field}>
                                            <FormLabel component="legend">
                                                {localStorage.getItem("language") === "en"
                                                    ? "How much time did you usually spend walking on one of those days?"
                                                    : "Combien de temps avez-vous passé à marcher lors d'une de ces journées? "}
                                                <IconButton
                                                    color="primary"
                                                    style={{ cursor: "pointer" }}
                                                    onClick={() => {
                                                        setSelectedTypes(["radio", "input"]);
                                                        setDialogOpen(true);
                                                    }}
                                                >
                                                    <GrCircleInformation />
                                                </IconButton>
                                            </FormLabel>
                                            <Grid container>
                                                <Grid item xs={6}>
                                                    <TextField
                                                        type="number"
                                                        InputProps={{ inputProps: { min: 0, max: 1440 } }}
                                                        variant="standard"
                                                        helperText={errors.question6?.message}
                                                        error={!!errors.question6?.message}
                                                        label={<Typography variant="h6">Minutes</Typography>}
                                                        {...field}
                                                        onChange={(e: any) => {
                                                            // check to see if the input is a number
                                                            if (!isNaN(Number(e))) {
                                                                field.onChange({ ...e, value: parseInt(e) });
                                                            } else {
                                                                field.onChange({ ...e, value: 0 });
                                                            }
                                                            setQ6Checked(e.target.checked);
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <FormControlLabel
                                                        value="0"
                                                        control={<Radio checked={q6Checked} onChange={e => setQ6Checked(e.target.checked)} />}
                                                        label={
                                                            localStorage.getItem("language") === "en"
                                                                ? "Don’t Know / Not Sure"
                                                                : "Ne sait pas / N’est pas sûr"
                                                        }
                                                    />
                                                </Grid>
                                            </Grid>
                                        </FormControl>
                                    )}
                                />
                            )}

                            <div className="forms__important__container">
                                <h6 className="forms__important">
                                    &emsp;
                                    {localStorage.getItem("language") === "en"
                                        ? "The last question is about the time you spent sitting on weekdays during the last 7 days. Include time spent at work, at home, while doing course work and during leisure time. This may include time spent sitting at a desk, visiting friends, reading, or sitting or lying down to watch television."
                                        : "La dernière question porte sur le temps que vous avez passé assis au cours des jours de semaine dans les sept derniers jours. Incluez le temps passé assis au travail, à la maison, lors de formations ou pendant vos loisirs. Cela peut inclure le temps passé assis à un bureau, lorsque vous rendez visite à des amis, que vous lisez, ou que vous êtes assis ou couché pour regarder la télévision."}
                                </h6>
                            </div>

                            <Controller
                                control={control}
                                name="question7"
                                render={({ field }) => (
                                    <FormControl id="question7" component="fieldset" error={!!errors.question7?.message} {...field}>
                                        <FormLabel component="legend">
                                            {localStorage.getItem("language") === "en"
                                                ? "How much time did you spend sitting on an average weekday (Monday to Friday) during the last 7 days?"
                                                : "Combien de temps avez-vous passé assis pendant une journée de semaine typique (du lundi au vendredi) au cours des 7 derniers jours ?"}
                                        <IconButton
                                                color="primary"
                                                style={{ cursor: "pointer" }}
                                                onClick={() => {
                                                    setSelectedTypes(["radio", "input"]);
                                                    setDialogOpen(true);
                                                }}
                                            >
                                                <GrCircleInformation />
                                            </IconButton>
                                        </FormLabel>
                                        <Grid container>
                                            <Grid item xs={6}>
                                                <TextField
                                                    type="number"
                                                    InputProps={{ inputProps: { min: 0, max: 24 } }}
                                                    variant="standard"
                                                    fullWidth
                                                    helperText={errors.question7?.message}
                                                    error={!!errors.question7?.message}
                                                    label={localStorage.getItem("language") === "en" ? "Hours per day" : "Heures par jour"}
                                                    {...field}
                                                    onChange={(e: any) => {
                                                        field.onChange(e);
                                                        setQ7Checked(e.target.checked);
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <FormControlLabel
                                                    style={{ paddingTop: 10 }}
                                                    value="0"
                                                    control={<Radio checked={q7Checked} onChange={e => setQ7Checked(e.target.checked)} />}
                                                    label={
                                                        localStorage.getItem("language") === "en"
                                                            ? "Don’t Know / Not Sure"
                                                            : "Ne sait pas / N’est pas sûr"
                                                    }
                                                />
                                            </Grid>
                                        </Grid>
                                        <FormHelperText style={{ fontSize: 20 }}>{errors.question7?.message}</FormHelperText>
                                    </FormControl>
                                )}
                            />
                        </div>
                    </Grid>
                    <Grid item>
                        <Button variant="contained" type="submit" className="questionnaires__cta" fullWidth>
                            {localStorage.getItem("language") === "en" ? "Submit" : "Soumettre"} <MdKeyboardArrowRight />
                        </Button>
                    </Grid>
                    <div id="spacer_for_button" style={{ minHeight: 30 }} />
                </form>
            </div>
            <InstructionDialog open={dialogOpen} setOpen={setDialogOpen} types={selectedTypes} />
        </>
    );
};

export default PhysicalActivityForm;
