import React, { useEffect, useState } from "react";
import moment from "moment";

// Mui
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";

// Interfaces
import { Participant } from "../../../../interfaces/Participant";
import { Chapter } from "../../../../interfaces/Chapter";

// Custom components
import SimpleTableWithoutPagination from "../../../general/SimpleTableWithoutPagination";
import ParticipantsTableRows from "./ParticipantsTableRows";
import SearchBar from "../../../general/SearchBar";

// Hooks
import useDb from "../../../../hooks/useDb";
import { useFetchUser } from "../../../../hooks/useFetchUser";

// Utils
import settings from "../../../../settings.json";
import { exportAOADocument } from "../../../../utils/xlsx";

// Firebase
import { firestore } from "../../../../firebase/firebase";
import { collection, query, orderBy, getDocs } from "firebase/firestore";
import { genDoc } from "../../../../firebase/requests";

const COLLECTION = "Participants";

const Users: React.FC = () => {
    const { currentAdmin, loading, setLoading } = useFetchUser();
    const chapterRequests = useDb<Chapter>("Chapters", currentAdmin);

    // States
    const [participants, setParticipants] = useState<Participant[]>([]);
    const [chapters, setChapters] = useState<Chapter[]>([]);

    const formatData = async () => {
        const dataArray: any[] = [];

        // Sorting by email asc
        participants.sort((a: Participant, b: Participant) => (a.email > b.email ? 1 : -1));

        dataArray.push([
            "Email",
            "Nickname",
            "DOB",
            "PSCID",
            "Account Creation",
            "Cycle Start",
            "Points",
            "Study",
            "WeeksInRow",
            "Language",
            "Prioritized Topics",
            "Deleted",
            "Deactivated",
        ]);

        participants.forEach(p => {
            let cycleStart = "N/A";
            if (p.cycleStart) cycleStart = moment(p.cycleStart).format("YYYY/MM/DD");

            dataArray.push([
                p.email,
                p.nickname,
                moment(p.dateOfBirth).format("YYYY/MM/DD"),
                p.pscid,
                moment(p.createdAt).format("YYYY/MM/DD"),
                cycleStart,
                p.points,
                p.study,
                p.weeksInRow,
                p.language,
                p.prioritizedTopics.join(","),
                p.isDeleted ? "Yes" : "No",
                p.deactivated ? "Yes" : "No",
            ]);
        });

        return dataArray;
    };

    const formatAllData = async () => {
        try {
            const dataArray: any[] = [];

            // const pilotEmails = [
            //     "ageorge08@yahoo.ca",
            //     "bi0nicear@yahoo.com",
            //     "bonnie.flatt@gmail.com",
            //     "bproctor1709@gmail.com",
            //     "fdiamondcooper@gmail.com",
            //     "jbertin@xplornet.com",
            //     "jimpark54@shaw.ca",
            //     "joel@joelhertz.ca",
            //     "margaret_fraser@telus.net",
            //     "nancyjc88@gmail.com",
            //     "nchamann@rogers.com",
            //     "ogeiger@telus.net",
            //     "olin@triumf.ca",
            //     "pkperry@nb.sympatico.ca",
            //     "richard.dopson@gmail.com",
            //     "robinburton415@gmail.com",
            //     "sdorren@nbnet.nb.ca",
            //     "sssstapleton@gmail.com",
            //     "toni.at.toronto@gmail.com",
            //     "z.rajwani@outlook.com",
            // ];

            const getFavouriteChaptersNameFromIds = (chapterIds: string[]) => {
                const chapterTitles: string[] = [];

                chapterIds.forEach(chapterId => {
                    const chapter = chapters.find(x => x.id === chapterId);

                    if (chapter) {
                        chapterTitles.push(chapter.titleEN);
                    }
                });

                return chapterTitles;
            };

            // Sorting by email asc
            participants.sort((a: Participant, b: Participant) => (a.email > b.email ? 1 : -1));

            const headers = [
                "Email",
                "Nickname",
                "DOB",
                "PSCID",
                "Account Creation",
                "Cycle Start",
                "Points",
                "Study",
                "WeeksInRow",
                "Language",
                "Deleted",
                "Deactivated",
                "Vascular Chapter 7 Hidden",
                "Vascular Chapter 8 Hidden",
                "Prioritized Topics (Current)",
                "Prioritized Topics (Stage 1)",
                "Prioritized Topics (Stage 2)",
                "Prioritized Topics (Stage 3)",
                "Prioritized Topics (Stage 4)",
                "Prioritized Topics (Stage 5)",
                "Completed Chapters (brain overview)",
                "Completed Chapters (cognitive)",
                "Completed Chapters (nutrition)",
                "Completed Chapters (physical)",
                "Completed Chapters (sleep)",
                "Completed Chapters (social phychological)",
                "Completed Chapters (vascular health)",
                "Completed Chapters (vision hearing)",
                "Completed Chapters (week 23)",
                "Completed Chapters (week 45)",
                "Overall chapter completion by name",
                "Time to complete chapter",
                "Favourites", // 19
                "Goals (Stage 1)",
                "Goals (Stage 2)",
                "Goals (Stage 3)",
                "Goals (Stage 4)",
                "Goals (Stage 5)",
                "Timeline week01",
                "Timeline week02",
                "Timeline week03",
                "Timeline week04",
                "Timeline week05",
                "Timeline week06",
                "Timeline week07",
                "Timeline week08",
                "Timeline week09",
                "Timeline week10",
                "Timeline week11",
                "Timeline week12",
                "Timeline week13",
                "Timeline week14",
                "Timeline week15",
                "Timeline week16",
                "Timeline week17",
                "Timeline week18",
                "Timeline week19",
                "Timeline week20",
                "Timeline week21",
                "Timeline week22",
                "Timeline week23",
                "Timeline week24",
                "Timeline week25",
                "Timeline week26",
                "Timeline week27",
                "Timeline week28",
                "Timeline week29",
                "Timeline week30",
                "Timeline week31",
                "Timeline week32",
                "Timeline week33",
                "Timeline week34",
                "Timeline week35",
                "Timeline week36",
                "Timeline week37",
                "Timeline week38",
                "Timeline week39",
                "Timeline week40",
                "Timeline week41",
                "Timeline week42",
                "Timeline week43",
                "Timeline week44",
                "Timeline week45",
                "Timeline week46",
                "Timeline week47",
                "Timeline week48",
                "Timeline week49",
                "Timeline week50",
                "Timeline week51",
                "Unlocked Chapters (brain overview)",
                "Unlocked Chapters (cognitive)",
                "Unlocked Chapters (nutrition)",
                "Unlocked Chapters (physical)",
                "Unlocked Chapters (sleep)",
                "Unlocked Chapters (social phychological)",
                "Unlocked Chapters (vascular health)",
                "Unlocked Chapters (vision hearing)",
                "Unlocked Chapters (week 23)",
                "Unlocked Chapters (week 45)",
            ];

            // Uses
            let maxUses = 0;
            participants.forEach(part => {
                if (part.uses.length > maxUses) {
                    maxUses = part.uses.length;
                }
            });

            for (let i = 1; i <= maxUses; i++) {
                headers.push(`Use ${i} start`);
                headers.push(`Use ${i} end`);
                headers.push(`Use ${i} chapters visited`);
                headers.push(`Use ${i} chapters completed`);
            }

            dataArray.push(headers);

            participants.forEach(p => {
                let cycleStart = "N/A";
                if (p.cycleStart) cycleStart = moment(p.cycleStart).format("YYYY/MM/DD");

                const completedChapters: string[] = [];
                Object.values(p.completedChapters).forEach(v => v.forEach(x => completedChapters.push(x)));

                const week23CompletedChaptersHistory: string[] = [];
                const week45CompletedChaptersHistory: string[] = [];
                const overallChapterCompletionByName: string[] = [];
                const timeToCompleteChaptersByName: string[] = [];

                if (p.completedChaptersTimer) {
                    // sort completedChaptersTimer
                    Object.values(p.completedChaptersTimer).forEach(completedChapterTimer => {
                        completedChapterTimer.forEach(timerObject => {
                            const chapter = chapters.find(chapter => chapter.id === timerObject.id);
                            timeToCompleteChaptersByName.push(`${chapter?.titleEN} ${timerObject.seconds.toFixed(1)} minute(s)`);
                        });
                    });
                }

                if (p.completedChaptersHistory) {
                    // Sort completedChaptersHistory between week23 and week45
                    Object.values(p.completedChaptersHistory).forEach(completedChapterHistory => {
                        if (p.cycleStart && completedChapterHistory.length) {
                            const cycleStart = new Date(p.cycleStart);
                            const week23 = new Date(p.cycleStart);
                            week23.setDate(week23.getDate() + 23 * 7);
                            const week45 = new Date(p.cycleStart);
                            week45.setDate(week45.getDate() + 45 * 7);

                            completedChapterHistory.forEach(x => {
                                const chapter = chapters.find(chapter => chapter.id === x.id);

                                if (!!chapter && new Date(x.date) >= cycleStart && new Date(x.date) <= week23) {
                                    // <= week 23
                                    week23CompletedChaptersHistory.push(chapter?.titleEN + " " + moment(x.date).format("YYYY-MM-DD hh:mm:ss"));
                                } else if (!!chapter && new Date(x.date) > week23 && new Date(x.date) <= week45) {
                                    // > week 23 & <= week 45
                                    week45CompletedChaptersHistory.push(chapter?.titleEN + " " + moment(x.date).format("YYYY-MM-DD hh:mm:ss"));
                                }
                            });
                        }
                    });

                    // Overall chapter completion by name
                    const completedChapterHistoryList: any[] = [];

                    Object.values(p.completedChaptersHistory).forEach(completedChapterHistory => {
                        completedChapterHistory.forEach(x => {
                            completedChapterHistoryList.push(x);
                        });
                    });

                    completedChapterHistoryList.sort((a, b) => (a.date < b.date ? -1 : 1));

                    completedChapterHistoryList.forEach(completedChapterHistory => {
                        const chapter = chapters.find(chapter => chapter.id === completedChapterHistory.id);
                        overallChapterCompletionByName.push(`${chapter?.titleEN} ${moment(completedChapterHistory.date).format("LL")}`);
                    });
                }

                const week23UnlockedChaptersHistory: string[] = [];
                const week45UnlockedChaptersHistory: string[] = [];

                if (p.unlockedChaptersHistory) {
                    // Sort unlockedChaptersHistory between week23 and week45
                    Object.values(p.unlockedChaptersHistory).forEach(unlockedChapterHistory => {
                        if (p.cycleStart && unlockedChapterHistory.length) {
                            const cycleStart = new Date(p.cycleStart);
                            const week23 = new Date(p.cycleStart);
                            week23.setDate(week23.getDate() + 23 * 7);
                            const week45 = new Date(p.cycleStart);
                            week45.setDate(week45.getDate() + 45 * 7);

                            unlockedChapterHistory.forEach(x => {
                                const chapter = chapters.find(chapter => chapter.id === x.id);

                                if (!!chapter && new Date(x.date) >= cycleStart && new Date(x.date) <= week23) {
                                    // <= week 23
                                    week23UnlockedChaptersHistory.push(chapter?.titleEN + " " + moment(x.date).format("YYYY-MM-DD hh:mm:ss"));
                                } else if (!!chapter && new Date(x.date) > week23 && new Date(x.date) <= week45) {
                                    // > week 23 & <= week 45
                                    week45UnlockedChaptersHistory.push(chapter?.titleEN + " " + moment(x.date).format("YYYY-MM-DD hh:mm:ss"));
                                }
                            });
                        }
                    });
                }

                const data = [
                    p.email,
                    p.nickname,
                    moment(p.dateOfBirth).format("YYYY/MM/DD"),
                    p.pscid,
                    moment(p.createdAt).format("YYYY/MM/DD"),
                    cycleStart,
                    p.points,
                    p.study,
                    p.weeksInRow,
                    p.language,
                    p.isDeleted ? "Yes" : "No",
                    p.deactivated ? "Yes" : "No",
                    p.vascularChapter7HiddenDate ? moment(p.vascularChapter7HiddenDate).format("DD-MM-YYYY hh:mm:ss") : "Not hidden",
                    p.vascularChapter8HiddenDate ? moment(p.vascularChapter8HiddenDate).format("DD-MM-YYYY hh:mm:ss") : "Not hidden",
                    p.prioritizedTopics.join(",\n"),
                    p.prioritizedTopicsHistory.stage1.join(",\n") ?? "",
                    p.prioritizedTopicsHistory.stage2.join(",\n") ?? "",
                    p.prioritizedTopicsHistory.stage3.join(",\n") ?? "",
                    p.prioritizedTopicsHistory.stage4.join(",\n") ?? "",
                    p.prioritizedTopicsHistory.stage5.join(",\n") ?? "",
                    // Completed chapters
                    Object.entries(p.completedChapters)
                        .map(e => e[0] === "Brain Overview" && e[1])
                        .map(e => e && e.map(x => chapters.find(chapter => chapter.id === (x as any))?.titleEN))
                        .filter(x => x && x)
                        .join(",\n"),
                    Object.entries(p.completedChapters)
                        .map(e => e[0] === "Cognitive" && e[1])
                        .map(e => e && e.map(x => chapters.find(chapter => chapter.id === (x as any))?.titleEN))
                        .filter(x => x && x)
                        .join(",\n"),
                    Object.entries(p.completedChapters)
                        .map(e => e[0] === "Nutrition" && e[1])
                        .map(e => e && e.map(x => chapters.find(chapter => chapter.id === (x as any))?.titleEN))
                        .filter(x => x && x)
                        .join(",\n"),
                    Object.entries(p.completedChapters)
                        .map(e => e[0] === "Physical" && e[1])
                        .map(e => e && e.map(x => chapters.find(chapter => chapter.id === (x as any))?.titleEN))
                        .filter(x => x && x)
                        .join(",\n"),
                    Object.entries(p.completedChapters)
                        .map(e => e[0] === "Sleep" && e[1])
                        .map(e => e && e.map(x => chapters.find(chapter => chapter.id === (x as any))?.titleEN))
                        .filter(x => x && x)
                        .join(",\n"),
                    Object.entries(p.completedChapters)
                        .map(e => e[0] === "Social Psychological" && e[1])
                        .map(e => e && e.map(x => chapters.find(chapter => chapter.id === (x as any))?.titleEN))
                        .filter(x => x && x)
                        .join(",\n"),
                    Object.entries(p.completedChapters)
                        .map(e => e[0] === "Vascular Health" && e[1])
                        .map(e => e && e.map(x => chapters.find(chapter => chapter.id === (x as any))?.titleEN))
                        .filter(x => x && x)
                        .join(",\n"),
                    Object.entries(p.completedChapters)
                        .map(e => e[0] === "Vision Hearing" && e[1])
                        .map(e => e && e.map(x => chapters.find(chapter => chapter.id === (x as any))?.titleEN))
                        .filter(x => x && x)
                        .join(",\n"),
                    week23CompletedChaptersHistory.join(",\n"),
                    week45CompletedChaptersHistory.join(",\n"),
                    overallChapterCompletionByName.join(",\n"),
                    timeToCompleteChaptersByName.join(",\n"),
                    getFavouriteChaptersNameFromIds(p.favourites).join(",\n"), // 19
                    p.goals.stage1.map((x, i) => `${i + 1}:${x.name} - ${x.description}`).join(",\n"),
                    p.goals.stage2.map((x, i) => `${i + 1}:${x.name} - ${x.description}`).join(",\n"),
                    p.goals.stage3.map((x, i) => `${i + 1}:${x.name} - ${x.description}`).join(",\n"),
                    p.goals.stage4.map((x, i) => `${i + 1}:${x.name} - ${x.description}`).join(",\n"),
                    p.goals.stage5.map((x, i) => `${i + 1}:${x.name} - ${x.description}`).join(",\n"),
                    Object.entries(p.stages)
                        .sort((a: any, b: any) => (a[0] < b[0] ? -1 : 1))
                        .map(e => `${e[0]}: ${e[1]}`)
                        .join(",\n"),
                ];

                // Timeline
                for (let i = 1; i <= 51; i++) {
                    const currentWeek = `week${i < 10 ? "0" : ""}${i}`;
                    if (i === 1 && (p.timeline as any)[currentWeek].length > 2) {
                        // week01 hardcoded chapters
                        ["z9FrQXipd4aNRgDmTzXC", "x6lmvU6hXZ9do1uM8zPn"]
                            .map(x => chapters.find(chapter => chapter.id === (x as any))?.titleEN)
                            .join(",\n");
                    } else if (i === 2 && (p.timeline as any)[currentWeek].length > 3) {
                        // week02 hardcoded chapters
                        ["AZAmWigxpmUJUh5w5eID", "QVD8R1AGEKUDuHLCxHV5", "qjT65BhHj8QZvCW0pj2c"]
                            .map(x => chapters.find(chapter => chapter.id === (x as any))?.titleEN)
                            .join(",\n");
                    } else {
                        data.push(
                            (p.timeline as any)[currentWeek].map((e: any) => chapters.find(chapter => chapter.id === (e as any))?.titleEN).join(",")
                        );
                    }
                }

                data.push(
                    // Unlocked chapters
                    Object.entries(p.unlockedChapters)
                        .map(e => e[0] === "Brain Overview" && e[1])
                        .map(e => e && e.map(x => chapters.find(chapter => chapter.id === (x as any))?.titleEN))
                        .filter(x => x && x)
                        .join(",\n"),
                    Object.entries(p.unlockedChapters)
                        .map(e => e[0] === "Cognitive" && e[1])
                        .map(e => e && e.map(x => chapters.find(chapter => chapter.id === (x as any))?.titleEN))
                        .filter(x => x && x)
                        .join(",\n"),
                    Object.entries(p.unlockedChapters)
                        .map(e => e[0] === "Nutrition" && e[1])
                        .map(e => e && e.map(x => chapters.find(chapter => chapter.id === (x as any))?.titleEN))
                        .filter(x => x && x)
                        .join(",\n"),
                    Object.entries(p.unlockedChapters)
                        .map(e => e[0] === "Physical" && e[1])
                        .map(e => e && e.map(x => chapters.find(chapter => chapter.id === (x as any))?.titleEN))
                        .filter(x => x && x)
                        .join(",\n"),
                    Object.entries(p.unlockedChapters)
                        .map(e => e[0] === "Sleep" && e[1])
                        .map(e => e && e.map(x => chapters.find(chapter => chapter.id === (x as any))?.titleEN))
                        .filter(x => x && x)
                        .join(",\n"),
                    Object.entries(p.unlockedChapters)
                        .map(e => e[0] === "Social Psychological" && e[1])
                        .map(e => e && e.map(x => chapters.find(chapter => chapter.id === (x as any))?.titleEN))
                        .filter(x => x && x)
                        .join(",\n"),
                    Object.entries(p.unlockedChapters)
                        .map(e => e[0] === "Vascular Health" && e[1])
                        .map(e => e && e.map(x => chapters.find(chapter => chapter.id === (x as any))?.titleEN))
                        .filter(x => x && x)
                        .join(",\n"),
                    Object.entries(p.unlockedChapters)
                        .map(e => e[0] === "Vision Hearing" && e[1])
                        .map(e => e && e.map(x => chapters.find(chapter => chapter.id === (x as any))?.titleEN))
                        .filter(x => x && x)
                        .join(",\n"),
                    week23UnlockedChaptersHistory.join(",\n"),
                    week45UnlockedChaptersHistory.join(",\n")
                );
                // Uses
                for (let i = 0; i < p.uses.length; i++) {
                    // start
                    if (p.uses[i].start) data.push(moment(p.uses[i].start).format("DD-MM-YYYY hh:mm:ss"));
                    else data.push("-");
                    // end
                    if (p.uses[i].end) data.push(moment(p.uses[i].end).format("DD-MM-YYYY hh:mm:ss"));
                    else data.push("-");
                    // chaptersVisited
                    if (p.uses[i].chaptersVisited && p.uses[i].chaptersVisited.length)
                        data.push(p.uses[i].chaptersVisited.map((e: any) => chapters.find(chapter => chapter.id === (e as any))?.titleEN).join(", "));
                    else data.push("-");
                    // chaptersCompleted
                    if (p.uses[i].chaptersCompleted && p.uses[i].chaptersCompleted.length)
                        data.push(
                            p.uses[i].chaptersCompleted.map((e: any) => chapters.find(chapter => chapter.id === (e as any))?.titleEN).join(", ")
                        );
                    else data.push("-");
                }

                dataArray.push(data);
            });

            return dataArray;
        } catch (e) {
            console.error(e);
            return [];
        }
    };

    const exportData = async () => {
        const fileName = `participants_${moment(new Date()).format("DD-MM-YYYY")}`;
        const sheetName = `${moment(new Date()).format("DD-MM-YYYY")}`;
        exportAOADocument(await formatData(), fileName, sheetName);
    };

    const exportAllData = async () => {
        const fileName = `participants_alldata_${moment(new Date()).format("DD-MM-YYYY")}`;
        const sheetName = `${moment(new Date()).format("DD-MM-YYYY")}`;
        exportAOADocument(await formatAllData(), fileName, sheetName);
    };

    const fetchChapters = async () => {
        try {
            setChapters((await chapterRequests.getAll({})) as Chapter[]);
        } catch (e) {
            console.error(e);
        }
    };

    const fetchParticipants = async () => {
        try {
            setLoading(true);
            let localQuery = undefined;

            if (currentAdmin?.roles.includes(settings.app.highestRole) || currentAdmin?.roles.includes("admin")) {
                localQuery = query(collection(firestore, COLLECTION), orderBy("email", "asc"));
            }

            if (localQuery) {
                const payload = await getDocs(localQuery);

                const data = payload.docs.map(genDoc<Participant>());

                setParticipants(data.filter(x => !x.pscid?.includes("syn2")));
            }
        } catch (e) {
            console.error(e);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchParticipants();
        fetchChapters();
    }, []);

    return (
        <>
            <Grid item container>
                <Grid item xs={8}>
                    <SearchBar title={`Participants (${participants.length})`} />
                </Grid>

                <Grid item xs={4} flexGrow={5} flex={1}>
                    <div style={{ float: "right", gap: 1 }}>
                        {(currentAdmin?.roles.includes(settings.app.highestRole) || currentAdmin?.roles.includes("admin")) && (
                            <>
                                <Button variant="contained" onClick={() => exportData()} style={{ marginTop: 20, marginRight: 5 }}>
                                    Export
                                </Button>

                                <Button variant="contained" onClick={() => exportAllData()} style={{ marginTop: 20 }}>
                                    Export all
                                </Button>
                            </>
                        )}
                    </div>
                </Grid>
            </Grid>

            <SimpleTableWithoutPagination
                loading={loading}
                data={participants}
                count={participants.length}
                rows={ParticipantsTableRows()}
                noMoreData={false}
            />
        </>
    );
};

export default Users;
